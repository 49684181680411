import { Itinerary, ItineraryAddress, ItineraryPoint, ItineraryTour } from '@app/store/models';
import { ItineraryEntity } from './itinirary';

export class ItineraryEntityDiff {
    date: Date;
    user: string | null;
    itineraries: ItineraryDiff[] = [];
    constructor(_old: ItineraryEntity, _new: ItineraryEntity) {
        this.date = _new.date ? new Date(_new.date) : new Date(0);
        this.user = _new.user;

        const count = _old.itineraries.length > _new.itineraries.length ? _old.itineraries.length : _new.itineraries.length;

        for (let i = 0; i < count; i++) {
            const oldItinerar = _old.itineraries[i] ?? new Itinerary();
            const newItinerar = _new.itineraries[i] ?? new Itinerary();
            this.itineraries.push(new ItineraryDiff(oldItinerar, newItinerar));
        }
    }
}

export class ItineraryDiff {
    start: ItineraryPointDiff;
    stop: ItineraryPointDiff;
    tour: ItineraryTourDiff;
    constructor(_old: Itinerary, _new: Itinerary) {
        this.start = new ItineraryPointDiff(_old.start, _new.start);
        this.stop = new ItineraryPointDiff(_old.stop, _new.stop);
        this.tour = new ItineraryTourDiff(_old.tour, _new.tour);
    }
}

export class ItineraryPointDiff {
    type: ValueDiff<'start' | 'stop' | 'transfer' | null>;
    address: ItineraryAddressDiff;
    constructor(_old?: ItineraryPoint | null, _new?: ItineraryPoint | null) {
        this.type = new ValueDiff(_old?.type ?? null, _new?.type ?? null);
        this.address = new ItineraryAddressDiff(_old?.address, _new?.address);
    }
}

export class ItineraryTourDiff {
    startTime: ValueDiff<string | null>;
    stopTime: ValueDiff<string | null>;
    vehicle: ValueDiff<string | null>;
    driver: ValueDiff<string | null>;
    phones: ValueDiff<string | null>[] = [];
    constructor(_old?: ItineraryTour, _new?: ItineraryTour) {
        this.startTime = new ValueDiff(_old?.startTime ?? null, _new?.startTime ?? null);
        this.stopTime = new ValueDiff(_old?.stopTime ?? null, _new?.stopTime ?? null);
        this.vehicle = new ValueDiff(_old?.vehicle ?? null, _new?.vehicle ?? null);
        this.driver = new ValueDiff(_old?.driver ?? null, _new?.driver ?? null);

        const phonesCount = (_old?.phones ?? []).length > (_new?.phones || []).length ? (_old?.phones ?? []).length : (_new?.phones || []).length;
        for (let i = 0; i < phonesCount; i++) {
            this.phones.push(new ValueDiff(_old?.phones[i] ?? null, _new?.phones[i] ?? null));
        }
    }
}

export class ItineraryAddressDiff {
    full: ValueDiff<string | null>;
    city: ValueDiff<string | null>;
    postalCode: ValueDiff<string | null>;
    constructor(_old?: ItineraryAddress | null, _new?: ItineraryAddress | null) {
        this.full = new ValueDiff(_old?.full ?? null, _new?.full ?? null);
        this.city = new ValueDiff(_old?.city ?? null, _new?.city ?? null);
        this.postalCode = new ValueDiff(_old?.postalCode ?? null, _new?.postalCode ?? null);
    }
}

export class ValueDiff<T> {
    old: T;
    new: T;
    status: 'added' | 'updated' | 'removed' | 'untouched';
    constructor(_old: T, _new: T) {
        this.old = _old;
        this.new = _new;
        if (this.old === this.new) {
            this.status = 'untouched';
        } else if (this.old === null && this.new !== null) {
            this.status = 'added';
        } else if (this.new === null && this.old !== null) {
            this.status = 'removed';
        } else {
            this.status = 'updated';
        }
    }
}
