<div [style.width.rem]="innerWidth" class="inner relative bg-lightest relative">
    <div #divBlock class="ps scroll">
        <div pFocusTrap>
            <h2 class="tw-mb-4 tw-text-[2rem]">{{ 'Nowa rezerwacja' }}</h2>

            <form appScrollToInvalidInput [ps]="ps" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="mb-12">
                    <app-box [border]="true">
                        <app-form-section [title]="'PARCELS.FORM.SECTIONS.sender_details' | translate">
                            <app-form-customer [isEditMode]="false" formGroupName="details" [translationScope]="'ORDER'" />
                        </app-form-section>
                    </app-box>
                </div>

                <h3 class="tw-mb-3 tw-text-[1.5rem]">{{ 'ORDER.SECTION.parcels' | translate }}</h3>

                <div formArrayName="parcels">
                    <div
                        @Grow
                        [@.disabled]="animationDisabled$ | async"
                        *ngFor="let additinalDriver of form.controls.parcels.controls; let i = index; let first = first"
                    >
                        <div class="pb-12">
                            <app-box [border]="true">
                                <div class="d-flex align-items-center border-bottom pb-6 mb-6">
                                    <span class="pin pin-secondary pin-lg fs-24 me-4">{{ i + 1 }}</span>
                                    <h4 class="mb-0 tw-text-secondary tw-font-semibold text-oneline tw-text-[1.25rem] me-4">
                                        {{ 'PARCELS.parcel' | translate }}
                                    </h4>
                                    <span
                                        *ngIf="first === false"
                                        class="pointer ms-auto"
                                        appIcon="x-lg"
                                        color="danger"
                                        size="7"
                                        (click)="onRemoveBookingClick($event, i)"
                                        (keypress)="onRemoveBookingClick($event, i)"
                                        tabindex="1"
                                    ></span>
                                </div>
                                <app-parcel-form [formGroupName]="i.toString()"></app-parcel-form>
                            </app-box>
                        </div>
                    </div>
                </div>

                <div class="mb-12 text-center">
                    <p-button (click)="onAddBookingClick($event)" type="submit" [label]="'PARCELS.BUTTONS.add_parcel' | translate" icon="pi pi-plus" />
                </div>

                <div class="mb-12">
                    <app-box [border]="true">
                        <div class="row">
                            <div class="col-6">
                                <app-form-dropdown
                                    [formControlGroup]="'details.partner'"
                                    [options]="(partnersSelect$ | async) || []"
                                    [block]="true"
                                    [showClear]="true"
                                    [label]="'BOOKINGS.FORM.partner' | translate"
                                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                                    [appendTo]="'body'"
                                    [showFilter]="true"
                                    [virtualScrollWidth]="42"
                                ></app-form-dropdown>
                            </div>
                            <div class="col-6">
                                <ng-container formArrayName="transactions">
                                    <ng-container *ngFor="let transaction of form.controls.transactions.controls; let i = index">
                                        <div [formGroupName]="i">
                                            <div class="row justify-content-end">
                                                <div class="col-6">
                                                    <app-form-dropdown
                                                        formControlGroup="currency"
                                                        [label]="'PAYMENTS.FORM.SERVICES_PAYMENTS.currency' | translate"
                                                        [options]="currencyTypes"
                                                        [block]="true"
                                                        [showClear]="true"
                                                        [showErrors]="false"
                                                        [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                                                    ></app-form-dropdown>
                                                </div>

                                                <div class="col-6">
                                                    <app-form-dropdown
                                                        formControlGroup="paymentMethod"
                                                        [label]="'PAYMENTS.FORM.SERVICES_PAYMENTS.payment_method' | translate"
                                                        [options]="paymentTypesItems$ | async"
                                                        [block]="true"
                                                        [showClear]="true"
                                                        [showErrors]="false"
                                                        [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                                                    ></app-form-dropdown>
                                                </div>
                                            </div></div
                                    ></ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </app-box>
                </div>
                <app-form-invoice
                    formGroupName="invoiceDetails"
                    [isInvoiceMethod]="isInvoiceMethod$ | async"
                    [partner]="form.controls.details.controls.partner"
                ></app-form-invoice>
                <div class="d-flex justify-content-end">
                    <p-button (click)="cancel($event)" label="Anuluj" type="button"></p-button>
                    <div class="ms-4">
                        <p-button
                            [disabled]="(autosuggestLoading$ | async) === true ? true : false"
                            label="Zapisz zamówienie"
                            icon="pi pi-save"
                            iconPos="left"
                            type="submit"
                        ></p-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
