import { Injectable, inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { FormValidators } from '@app/shared/forms';
import {
    ITEM_STATUS,
    CURRENCY_TYPES,
    PAYMENT_STATUS,
    PAYMENT_TYPES,
    SERVICE_TYPES,
    OrderModel,
    PaymentsOrderModel,
    TransactionOrderModel,
    OrderBookingParcelModel,
} from '@app/store/order';
import { GeoPositionModel } from '@app/store/planning/models';
import { OrderRouteAddressModel } from '@app/store/order/models/order.addresses.model';
import { CUSTOMER_TYPE } from '@app/store/order/enums/customer-type.enum';
import { PLANNING_TYPE } from '@app/store/planning/enums';
import { OrderParcelFormUpdateGroup, ParcelUpdateFormGroup } from '../models/form.value';
import { InvoiceDetailsFormGroup, ItemFormGroup, PaymentsFormGroup } from '../../../shared/models/form.value';
import { ACCURACY_TYPES } from '@app/store/order/enums/accuracy.enum';
import { map, startWith, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    private formBuilder = inject(NonNullableFormBuilder);

    form: FormGroup<OrderParcelFormUpdateGroup> = this.formBuilder.group<OrderParcelFormUpdateGroup>({
        details: this.formBuilder.group({
            firstName: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required, FormValidators.Text] }),
            lastName: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required, FormValidators.Text] }),
            phone: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required] }),
            email: this.formBuilder.control<string | null>('', { validators: [FormValidators.Email, FormValidators.Required] }),
            partner: this.formBuilder.control<string | null>(null, { validators: [FormValidators.Required] }),
            teamNumber: this.formBuilder.control<string | null>(null),
        }),
        invoiceDetails: this.formBuilder.group<InvoiceDetailsFormGroup>({
            address: this.formBuilder.group({
                city: this.formBuilder.control<null | string>(''),
                country: this.formBuilder.control<null | string>(''),
                houseNumber: this.formBuilder.control<null | string>(''),
                postalCode: this.formBuilder.control<null | string>(''),
                street: this.formBuilder.control<null | string>(''),
            }),
            isCompany: this.formBuilder.control<boolean | null>(null),
            invoice: this.formBuilder.control<boolean>(false),
            nip: this.formBuilder.control<string | null>('', { validators: [FormValidators.ForeignNip, FormValidators.MaxLength(16)] }),
            companyName: this.formBuilder.control<string | null>({ value: '', disabled: false }),
        }),
        parcels: this.formBuilder.array<FormGroup<ParcelUpdateFormGroup>>([]),
        transactions: this.formBuilder.array<FormGroup<PaymentsFormGroup>>([]),
    });

    constructor() {
        this.watchResignReasonValidator();
    }

    setValue(order: OrderModel, bookingId: string) {
        this.form.patchValue(
            {
                details: {
                    firstName: order.customer.firstName,
                    lastName: order.customer.lastName,
                    phone: order.customer.phone,
                    email: order.customer.email,

                    partner: order.partner?.id || null,
                    teamNumber: order.teamNumber,
                },
                invoiceDetails: {
                    companyName: order.customer.companyName,
                    nip: order.customer.nip,
                    address: {
                        city: order.customer.address.city,
                        country: order.customer.address.country,
                        houseNumber: order.customer.address.houseNumber,
                        postalCode: order.customer.address.postalCode,
                        street: order.customer.address.street,
                    },
                    isCompany: order.customer.type === CUSTOMER_TYPE.COMPANY ? true : false,
                    invoice: order.invoice,
                },
            },
            { emitEvent: false },
        );
        this.form.controls.invoiceDetails.controls.nip.addValidators(FormValidators.Text);

        // invoice
        this.form.controls.invoiceDetails.controls.invoice.patchValue(order.invoice);
        //bookings
        if (this.form.controls.invoiceDetails.controls.isCompany.value === true) {
            this.form.controls.invoiceDetails.controls.nip.addValidators([FormValidators.Required]);
        }
        // order bookings with edited booking on top
        const bookings = [...order.bookings];
        const index = bookings.findIndex((booking) => booking.id === bookingId);
        bookings.unshift(bookings.splice(index, 1)[0]);
        bookings.forEach((booking) => {
            if ('parcel' in booking) {
                this.addBookingFormWithValue(booking);
            }
        });

        // transactions
        const transactions = [...order.transactions];
        // move main transaction to top
        const transactionWithRidesIndex = transactions.findIndex((transaction) => transaction.items.find((item) => item.type === SERVICE_TYPES.RIDE));
        const transactionWithRides = transactions[transactionWithRidesIndex];
        if (transactionWithRides) {
            transactions.splice(transactionWithRidesIndex, 1);
            transactions.unshift(transactionWithRides);
        }
        transactions.forEach((transaction) => this.addTransactionFormWithValue(transaction, order.payments));
    }

    addTransaction() {
        const transactionFormGroup = this.formBuilder.group({
            id: this.formBuilder.control<string | null>(null),
            paymentMethod: this.formBuilder.control<PAYMENT_TYPES | null>(null, { validators: FormValidators.Required }),
            currency: this.formBuilder.control<CURRENCY_TYPES | null>(null, { validators: FormValidators.Required }),
            items: this.formBuilder.array<FormGroup<ItemFormGroup>>([
                this.formBuilder.group({
                    id: this.formBuilder.control<string | null>({ value: null, disabled: true }),
                    notes: this.formBuilder.control<string | null>({ value: '', disabled: false }),
                    price: this.formBuilder.control<number>({ value: 0, disabled: false }),
                    type: this.formBuilder.control<SERVICE_TYPES | null>({ value: null, disabled: false }, { validators: FormValidators.Required }),
                    bookingId: this.formBuilder.control<string | null>({ value: null, disabled: false }, { validators: FormValidators.Required }),
                    name: this.formBuilder.control<string | null>({ value: '', disabled: false }),
                    status: this.formBuilder.control<ITEM_STATUS | null>({ value: null, disabled: false }),
                }),
            ]),
            closed: this.formBuilder.control<boolean>(false),
            paid: this.formBuilder.control<boolean>(false),
            status: this.formBuilder.control<PAYMENT_STATUS | null>(null),
        });
        this.form.controls.transactions.push(transactionFormGroup);
    }

    cleanup() {
        while (this.form.controls.parcels.length > 0) {
            this.form.controls.parcels.removeAt(0);
        }
        while (this.form.controls.transactions.length > 0) {
            this.form.controls.transactions.removeAt(0);
        }
        this.form.controls.invoiceDetails.controls.isCompany.removeValidators(FormValidators.RequiredTrue);
        this.form.controls.invoiceDetails.controls.invoice.removeValidators(FormValidators.RequiredTrue);
        this.form.controls.invoiceDetails.controls.nip.removeValidators([FormValidators.Required]);
        this.form.controls.invoiceDetails.controls.isCompany.enable();
        this.form.reset();
    }

    private addBookingFormWithValue(booking: OrderBookingParcelModel) {
        const parcelFormGroup = this.formBuilder.group({
            id: this.formBuilder.control<string | null>(booking.id),
            planningType: this.formBuilder.control<PLANNING_TYPE | null>(
                { value: booking.planningType, disabled: true },
                { validators: [FormValidators.Required] },
            ),
            internalNotes: this.formBuilder.control<string | null>(booking.internalNotes, { validators: FormValidators.MaxLength(1023) }),
            contentDescription: this.formBuilder.control<string | null>(booking.parcel.contentDescription, { validators: FormValidators.MaxLength(1023) }),
            driverNotes: this.formBuilder.control<string | null>(booking.driverNotes, { validators: FormValidators.MaxLength(1023) }),
            notes: this.formBuilder.control<string | null>(booking.notes, { validators: FormValidators.MaxLength(1023) }),
            size: this.formBuilder.control<string>(booking.parcel.size, { validators: [FormValidators.Required] }),
            weight: this.formBuilder.control<number | null>(booking.parcel.weight, { validators: [FormValidators.Required, FormValidators.MinValue(0.1)] }),
            value: this.formBuilder.control<number>(booking.parcel.value, { validators: [FormValidators.Required, FormValidators.MinValue(0.1)] }),
            departureDate: this.formBuilder.control<Date | null>(booking.route.departureDate, { validators: [Validators.required] }),
            didntGo: this.formBuilder.control(booking.didntGo),
            complaint: this.formBuilder.control(booking.complaint),
            from: this.addBookingAddressFormWithValue(booking.route.from),
            to: this.addBookingAddressFormWithValue(booking.route.to),
            resignReason: this.formBuilder.control(booking.resignReason),
            didntGoReason: this.formBuilder.control(booking.didntGoReason),
            recipient: this.formBuilder.group({
                firstname: this.formBuilder.control<string | null>(booking.parcel.recipient!.firstName, {
                    validators: [FormValidators.Required, FormValidators.Text],
                }),
                surname: this.formBuilder.control<string | null>(booking.parcel.recipient!.lastName, {
                    validators: [FormValidators.Required, FormValidators.Text],
                }),
                email: this.formBuilder.control<string | null>(booking.parcel.recipient!.email, {
                    validators: [FormValidators.Email, FormValidators.Required],
                }),
                phone: this.formBuilder.control<string | null>(booking.parcel.recipient!.phone, { validators: [FormValidators.Required] }),
            }),
        });

        this.form.controls.parcels.push(parcelFormGroup);
    }

    private addTransactionFormWithValue(transaction: TransactionOrderModel, payments: PaymentsOrderModel[]) {
        const payment = payments.find((payment) => payment.id === transaction.paymentId) || null;
        const transactionFormGroup = this.formBuilder.group({
            id: this.formBuilder.control<string | null>(transaction.id),
            paymentMethod: this.formBuilder.control<PAYMENT_TYPES | null>(
                { value: payment?.method || null, disabled: transaction.closed },
                { validators: FormValidators.Required },
            ),
            currency: this.formBuilder.control<CURRENCY_TYPES | null>(
                { value: transaction.currency || null, disabled: transaction.closed },
                { validators: FormValidators.Required },
            ),
            items: this.addItemsToTransaction(transaction),
            closed: this.formBuilder.control<boolean>(transaction.closed),
            paid: this.formBuilder.control<boolean>({
                value: payment?.status === 'paid' || payment?.status === 'completed' || false,
                disabled: transaction.closed,
            }),
            status: this.formBuilder.control<PAYMENT_STATUS | null>(payment?.status || null),
        });
        this.form.controls.transactions.push(transactionFormGroup);
    }

    private addItemsToTransaction(transaction: TransactionOrderModel) {
        const sortedItems = transaction.items.slice().sort((a, b) => a.bookingId.localeCompare(b.bookingId));
        const itemsFormGroup = this.formBuilder.array<FormGroup<ItemFormGroup>>([]);
        sortedItems.forEach((item) => {
            itemsFormGroup.push(
                this.formBuilder.group({
                    id: this.formBuilder.control<string | null>({ value: item.id, disabled: true }),
                    notes: this.formBuilder.control<string | null>({
                        value: item.notes,
                        disabled: item.resultStatus === 'resigned' || transaction.closed === true,
                    }),
                    price: this.formBuilder.control<number>({ value: item.price, disabled: item.resultStatus === 'resigned' || transaction.closed === true }),
                    type: this.formBuilder.control<SERVICE_TYPES | null>({ value: item.type, disabled: true }, { validators: FormValidators.Required }),
                    bookingId: this.formBuilder.control<string | null>({ value: item.bookingId, disabled: true }, { validators: FormValidators.Required }),
                    name: this.formBuilder.control<string | null>({ value: item.name, disabled: true }),
                    status: this.formBuilder.control<ITEM_STATUS | null>({ value: item.resultStatus, disabled: true }),
                }),
            );
        });

        return itemsFormGroup;
    }

    private addBookingAddressFormWithValue(address: OrderRouteAddressModel | null) {
        return this.formBuilder.group({
            id: this.formBuilder.control<string | null>({ value: address?.id || null, disabled: true }),
            term: this.formBuilder.control<string>(address?.term || ''),
            isPoint: this.formBuilder.control<boolean>({ value: address?.isPoint || false, disabled: true }),
            country: this.formBuilder.control<string | null>({ value: address?.country || null, disabled: false }, { validators: [FormValidators.Required] }),
            countryRestricted: this.formBuilder.control<boolean>({ value: true || null, disabled: true }),
            city: this.formBuilder.control<string | null>({ value: address?.city || null, disabled: true }, { validators: [] }),
            street: this.formBuilder.control<string | null>({ value: address?.street || null, disabled: true }, { validators: [] }),
            houseNumber: this.formBuilder.control<string | null>({ value: address?.houseNumber || null, disabled: true }, { validators: [] }),
            accuracy: this.formBuilder.control<ACCURACY_TYPES>({ value: address?.accuracy || ACCURACY_TYPES.HOUSE_NUMBER, disabled: true }, { validators: [] }),
            postalCode: this.formBuilder.control<string | null>(
                { value: address?.postalCode || null, disabled: true },
                {
                    validators: [FormValidators.MaxLength(10)],
                },
            ),
            geoPosition: this.formBuilder.control<GeoPositionModel>(
                address?.geoPosition || {
                    latitude: null,
                    longitude: null,
                },
            ),
        });
    }

    private watchResignReasonValidator() {
        this.form.controls.transactions.valueChanges
            .pipe(
                startWith(true),
                map(() => {
                    const items = this.form.controls.transactions
                        .getRawValue()
                        .map((transaction) => transaction.items)
                        .flat()
                        .filter((item) => item.type === SERVICE_TYPES.RIDE);

                    return items;
                }),
                tap((items) => {
                    items.forEach((item) => {
                        if (item.status !== 'resigned') {
                            this.clearResigned(item.bookingId);
                        }
                    });
                }),
            )
            .subscribe();
    }

    private clearResigned(bookingId: string | null) {
        if (bookingId !== null) {
            const index = this.form.controls.parcels.getRawValue().findIndex((passenger) => passenger.id === bookingId);
            if (index !== -1) {
                this.form.controls.parcels.at(index).controls.resignReason.patchValue(null, { emitEvent: false });
            }
        }
    }
}
