import { Component, Input, OnInit, inject } from '@angular/core';
import { OrderParcelUpdateValue, OrderPassengerUpdateValue } from '@app/store/order';
import { Time } from '@app/shared/classes';
import { DictionariesFacade } from '@app/dictionaries/store/facades/dictionaries.facade';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-booking-details',
    templateUrl: './booking-details-info.component.html',
    styleUrls: ['./booking-details-info.component.scss'],
})
export class BookingDetailsComponent implements OnInit {
    private readonly dictionariesFacade = inject(DictionariesFacade);

    @Input() value!: OrderPassengerUpdateValue | OrderParcelUpdateValue;

    planningType$!: Observable<string | null>;

    ngOnInit() {
        this.planningType$ = this.dictionariesFacade.planningTypes$.pipe(
            map((options) => options.find((option) => option.value === this.value.planningType)?.label ?? null),
        );
    }

    get departureDate() {
        return this.value.departureDate ? Time.dateToString(this.value.departureDate) : '-';
    }
}
