import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ItineraryEntityDiff } from '@app/store/history/models/itinirary-diff';

@Component({
    selector: 'app-itinirary-entry',
    templateUrl: './itinirary-entry.component.html',
    styleUrls: ['./itinirary-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItiniraryEntryComponent {
    @Input() itinirary!: ItineraryEntityDiff;
    @Input() last!: boolean;

    @HostBinding('class.last') get class() {
        return this.last;
    }
}
