<div class="d-flex align-items-center mb-4">
    <h4 class="tw-text-ellipsis tw-mr-auto tw-text-[1.625rem] tw-font-semibold">
        {{ passengerName() || 'Pasażer' }}
    </h4>
    {{ 'ORDER.BOOKING_DETAILS.unique_number' | translate }} <span class="fw-700">{{ booking.externalId || '---' }}</span>
</div>

<ng-container *ngIf="form">
    <app-form-section [accordion]="true" [open]="false" size="sm" [title]="'BOOKINGS.FORM.SECTIONS.planning_type' | translate">
        <div class="border rounded p-4">
            <app-booking-details [value]="form.getRawValue()"></app-booking-details>
        </div>
    </app-form-section>

    <app-form-section [accordion]="true" [open]="false" size="sm" [title]="'BOOKINGS.FORM.SECTIONS.passenger_details' | translate">
        <div class="border rounded p-4">
            <app-booking-personal-info [value]="form.getRawValue().personalDetails"></app-booking-personal-info>
        </div>
    </app-form-section>

    <app-form-section [accordion]="true" [open]="false" size="sm" [title]="'BOOKINGS.FORM.SECTIONS.departure-and-destination' | translate">
        <div class="row">
            <div class="col-6">
                <div class="border rounded p-4">
                    <app-booking-address-details [value]="form.getRawValue().from" />
                </div>
            </div>
            <div class="col-6">
                <div class="border rounded p-4">
                    <app-booking-address-details [value]="form.getRawValue().to" />
                </div>
            </div>
        </div>
    </app-form-section>

    <app-form-section
        *ngIf="booking.itineraries.items.length > 0"
        [accordion]="true"
        [open]="false"
        size="sm"
        [title]="'BOOKINGS.FORM.SECTIONS.itiniray' | translate"
    >
        <div class="w-100">
            <app-itinerary-details [itineraries]="booking.itineraries.items" />
        </div>
    </app-form-section>

    <app-form-section
        *ngIf="form.controls.internalNotes.getRawValue() || form.controls.driverNotes.getRawValue() || form.controls.notes.getRawValue()"
        [accordion]="true"
        [open]="false"
        size="sm"
        [title]="'BOOKINGS.FORM.SECTIONS.misc' | translate"
    >
        <ng-container *ngIf="form.controls.notes.getRawValue()">
            <div class="pb-12">
                <div class="fs-16">
                    <span class="fw-700">{{ 'ORDER.SECTION.notes' | translate }}:</span> {{ form.controls.notes.getRawValue() }}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="form.controls.driverNotes.getRawValue()">
            <div class="pb-12">
                <div class="fs-16">
                    <span class="fw-700">{{ 'ORDER.SECTION.driver_notes' | translate }}:</span> {{ form.controls.driverNotes.getRawValue() }}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="form.controls.internalNotes.getRawValue()">
            <div class="pb-12">
                <div class="fs-16">
                    <span class="fw-700">{{ 'ORDER.SECTION.internal_notes' | translate }}:</span> {{ form.controls.internalNotes.getRawValue() }}
                </div>
            </div>
        </ng-container>
    </app-form-section>
</ng-container>

<div class="d-flex justify-content-end pt-8">
    <p-button (click)="editBooking($event)" [label]="'ORDER.BUTTON.edit_booking' | translate"></p-button>
</div>
