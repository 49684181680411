import { ControlContainer, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { CURRENCY_TYPES, ITEM_STATUS, OrderFacade, PAYMENT_TYPES, PaymentsOrderModel, SERVICE_TYPES } from '@app/store/order';
import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Observable, Subscription, combineLatest, filter, first, map, startWith, tap } from 'rxjs';
import { FormValidators } from '@app/shared/forms';
import { PaymentsFormGroup } from '../../../../../../../shared/models/form.value';
import { PopupService } from '@app/shared/popup';
import { ConfirmPopupComponent } from '@app/shared/popups/confirm-popup/confirm-popup.component';
import { UserFacade } from '@app/modules/user/store/facades/user.facade';
import { Roles } from '@app/modules/permissions';
@Component({
    selector: 'app-transaction-set-edit',
    templateUrl: './transaction-set-edit.component.html',
    styleUrls: ['./transaction-set-edit.component.scss'],
})
export class TransactionSetEditComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly popupService = inject(PopupService);
    private readonly userFacade = inject(UserFacade);
    @Input() closed!: boolean;

    @Input() transactionIndex!: number;
    @Output() deleteTransactionEvent = new EventEmitter<number>();

    user$ = this.userFacade.user$;
    cartTitle!: string;
    priceTotal$!: Observable<string>;

    currencyTypes = Object.values(CURRENCY_TYPES).map((type) => ({ label: type, value: type }));
    paymentTypesItems$!: Observable<{ label: string; value: string; disabled?: boolean }[]>;

    form!: FormGroup<PaymentsFormGroup>;
    order$ = this.orderFacade.order$;
    payment$!: Observable<PaymentsOrderModel | null>;

    private subscriptions$ = new Subscription();

    constructor(
        private readonly controlContainer: ControlContainer,
        private readonly translateService: TranslateService,
        private readonly formBuilder: NonNullableFormBuilder,
        private readonly orderFacade: OrderFacade,
    ) {}

    ngOnInit(): void {
        this.form = <FormGroup<PaymentsFormGroup>>this.controlContainer.control;
        this.payment$ = combineLatest([this.order$, this.form.valueChanges.pipe(startWith(this.form.getRawValue()))]).pipe(
            map(([order, value]) => {
                const paymentId = order.transactions.find((transaction) => transaction.id === value.id)?.paymentId;
                if (paymentId) {
                    const payment = order.payments.find((payment) => payment.id === paymentId);
                    if (payment) {
                        return payment;
                    }
                }
                return null;
            }),
        );

        this.priceTotal$ = this.form.controls.items.valueChanges.pipe(
            startWith([]),
            map(() => this.form.controls.items.getRawValue()),
            map((items) =>
                items.filter((item) => {
                    if (item.id === null) {
                        return true;
                    }
                    return item.status !== 'resigned';
                }),
            ),
            map((items) => {
                return items
                    .reduce((sum, item) => {
                        if (item.type === SERVICE_TYPES.DISCOUNT) {
                            return sum - (item.price || 0);
                        }
                        return sum + (item.price || 0);
                    }, 0)
                    .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
            }),
        );
        this.paymentTypesItems$ = combineLatest([this.user$]).pipe(
            map(([user]) => {
                return Object.values(PAYMENT_TYPES)

                    .map((type) => ({
                        label: <string>this.translateService.instant(`payment.payment-type.${type}.label`),
                        value: type,
                        disabled:
                            (user?.role === Roles.ROLE_PARTNER_EMPLOYEE || user?.role === Roles.ROLE_PARTNER_ACCOUNTANT) &&
                            (type === PAYMENT_TYPES.GROUP_SETTLEMENT || type === PAYMENT_TYPES.CASH || type === PAYMENT_TYPES.FREE_CHECKOUT),
                    }));
            }),
            tap((paymentTypes) => {
                const currentPaymentType = this.form.controls.paymentMethod.value;
                if (!paymentTypes.find((paymentType) => paymentType.value === currentPaymentType)) {
                    this.form.controls.paymentMethod.setValue(null);
                }
            }),
        );
    }

    ngAfterViewInit(): void {
        const paidControl = this.form.controls.paid;
        this.subscriptions$.add(
            paidControl.valueChanges
                .pipe(
                    filter((value) => value === true),
                    tap(() => {
                        paidControl.setValue(false, { emitEvent: false });
                        this.confirmPaid();
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    addItemClick(event: MouseEvent) {
        event.preventDefault();
        this.form.controls.items.push(
            this.formBuilder.group({
                id: this.formBuilder.control<string | null>({ value: null, disabled: true }),
                type: this.formBuilder.control<SERVICE_TYPES | null>(null, { validators: [FormValidators.Required] }),
                notes: this.formBuilder.control<string | null>(''),
                price: this.formBuilder.control<number>(0, { validators: [FormValidators.Required] }),
                bookingId: this.formBuilder.control<string | null>({ value: null, disabled: false }, { validators: [FormValidators.Required] }),
                name: this.formBuilder.control<string | null>(''),
                status: this.formBuilder.control<ITEM_STATUS | null>(null),
            }),
        );
    }

    onDeleteItemEvent(index: number) {
        this.form.controls.items.removeAt(index);
        if (this.form.controls.items.length === 0) {
            this.deleteTransactionEvent.next(this.transactionIndex);
        }
    }

    onCreateItemEvent(event: number) {
        event;
    }

    confirmPaid() {
        const data = {
            messageKey: this.translateService.instant('MODAL.confirm_paid'),
            confirmKey: 'button.yes',
            cancelKey: 'button.no',
        };
        this.popupService.open(ConfirmPopupComponent, data);

        this.subscriptions$.add(
            this.popupService.closingSubject
                .pipe(
                    first(),
                    filter((closeReason) => closeReason === 'confirm'),
                    tap(() => {
                        this.form.controls.paid.setValue(true, { emitEvent: false });
                    }),
                )
                .subscribe(),
        );
    }

    onRemoveTransactionClick(event: MouseEvent | KeyboardEvent, index: number) {
        event.preventDefault();
        this.deleteTransactionEvent.next(index);
    }
}
