<div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" class="tour rounded line">
    <div class="d-flex flex-column gap-2 p-4">
        <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="item-{{ severity(itinerary.tour.startTime.status) }}">{{
                (itinerary.tour.startTime.status === 'removed' ? itinerary.tour.startTime.old : itinerary.tour.startTime.new) ?? '--:--'
            }}</span>
            <span></span>
            <span class="item-{{ severity(itinerary.tour.stopTime.status) }}">{{
                (itinerary.tour.stopTime.status === 'removed' ? itinerary.tour.stopTime.old : itinerary.tour.stopTime.new) ?? '--:--'
            }}</span>
        </div>

        <div class="d-flex align-items-center">
            <span class="me-2" appIcon="car-front" [color]="severity(itinerary.tour.vehicle.status)" size="5"></span>
            <span class="item-{{ severity(itinerary.tour.vehicle.status) }}">{{
                (itinerary.tour.vehicle.status === 'removed' ? itinerary.tour.vehicle.old : itinerary.tour.vehicle.new) ?? '-'
            }}</span>
        </div>

        <div class="d-flex align-items-center">
            <span class="me-2" appIcon="person-vcard" [color]="severity(itinerary.tour.driver.status)" size="5"></span>
            <span class="item-{{ severity(itinerary.tour.driver.status) }}">{{
                (itinerary.tour.driver.status === 'removed' ? itinerary.tour.driver.old : itinerary.tour.driver.new) ?? '-'
            }}</span>
        </div>
    </div>
    <div [style.height.rem]="0">
        <div class="phones" [@openClose]="hover$ | async">
            <div class="px-4 pb-4">
                <div
                    class="d-flex align-items-center"
                    *ngFor="let phone of itinerary.tour.phones; let last = last; let first = first"
                    [class.pb-2]="!last"
                    [class.pt-2]="first"
                >
                    <span class="me-2" appIcon="telephone" [color]="severity(phone.status)" size="5"></span>
                    <span class="item-{{ severity(phone.status) }}">{{ (phone.status === 'removed' ? phone.old : phone.new) ?? '-' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
