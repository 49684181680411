import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HistoryState } from '../reducers/history.reducer';
import { HISTORY_FEATURE_KEY } from '../keys';

export const selectState = createFeatureSelector<HistoryState>(HISTORY_FEATURE_KEY);
export const selectHistory = createSelector(selectState, (state) => state.history);
export const selectItineraries = createSelector(selectState, (state) => state.itineraries);
export const selectAll = createSelector(selectState, (state) => {
    if (state.itineraries !== null && state.history !== null) {
        return [...state.itineraries, ...state.history].sort((a, b) => (a.date > b.date ? -1 : 1));
    }
    return null;
});
