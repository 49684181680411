import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, createUrlTreeFromSnapshot } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { OrderBookingParcelModel } from '@app/store/order';
import { FormService } from '../../../../services/form-edit.service';
import { ParcelUpdateFormGroup } from '../../../../models/form.value';

@Component({
    selector: 'app-parcel-section',
    templateUrl: './parcel-section.component.html',
    styleUrls: ['./parcel-section.component.scss'],
})
export class ParcelSectionComponent implements OnInit {
    @Input() booking!: OrderBookingParcelModel;
    form?: FormGroup<ParcelUpdateFormGroup>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formService: FormService,
    ) {}

    ngOnInit() {
        const index = this.formService.form.controls.parcels.value.findIndex((value) => value.id === this.booking.id);
        this.form = this.formService.form.controls.parcels.at(index);
    }

    editParcel(event: MouseEvent) {
        event.preventDefault();
        const tree = createUrlTreeFromSnapshot(this.activatedRoute.snapshot, []);
        const parameters = { ...tree.root.children['primary'].segments[0].parameters };
        parameters['parcel-edit'] = this.booking.id ?? '';
        tree.root.children['primary'].segments[0].parameters = parameters;
        this.router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
    }
}
