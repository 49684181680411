import { Itinerary } from '@app/store/models';
import { ItineraryEntityDto } from './itinirary.dto';

export class ItineraryEntity {
    date: Date | null;
    user: string | null;
    itineraries: Itinerary[];
    constructor(data?: ItineraryEntityDto) {
        this.date = data?.date ? new Date(new Date(data.date).getTime() + new Date().getTimezoneOffset() * 60000) : null;
        this.user = data?.user ?? null;
        this.itineraries = (data?.itineraries || []).map((itinerary) => new Itinerary(itinerary));
    }
}
