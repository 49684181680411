import { Pipe, PipeTransform, inject } from '@angular/core';
import { OrderFacade } from '@app/store/order';
import { Observable, filter, map } from 'rxjs';

@Pipe({
    name: 'pickupNotes',
})
export class PickupNotesPipe implements PipeTransform {
    orderFacade$ = inject(OrderFacade);
    transform(id: string | null): Observable<string | null> {
        return this.orderFacade$.order$.pipe(
            filter(() => id !== null),
            map((order) => {
                const booking = order.bookings.find((booking) => booking.id === id);
                if (booking && 'parcel' in booking) {
                    return booking.parcel.pickupNotes;
                }
                return null;
            }),
        );
    }
}
