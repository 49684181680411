import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Subscription, combineLatest, distinctUntilChanged, map, startWith, tap } from 'rxjs';
import { BOOKING_TYPES } from '@app/store/planning/enums';
import { PassengerCreateFormGroup, PassengerUpdateFormGroup } from '../../../models/form.value';
import { Grow } from '@app/shared/animations';
import { OrderPersonalDetailsValue } from '@app/store/order';
import { AutosuggestClosestFacade, AutosuggestFacade } from '@app/store/heremaps';
import { DictionariesFacade } from '@app/dictionaries/store/facades/dictionaries.facade';
import { SelectOptionsFacade } from '@app/store/select-options';
import { PopupService } from '@app/shared/popup';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';

@Component({
    selector: 'app-booking-form',
    templateUrl: './booking-form.component.html',
    styleUrls: ['./booking-form.component.scss'],
    animations: [Grow],
})
export class BookingFormComponent implements OnInit, OnDestroy {
    private readonly selectOptionsFacade = inject(SelectOptionsFacade);
    private readonly controlContainer = inject(ControlContainer);
    private readonly autosuggestFacade = inject(AutosuggestFacade);
    private readonly autosuggestClosestFacade = inject(AutosuggestClosestFacade);
    private readonly dictionariesFacade = inject(DictionariesFacade);
    private readonly popupService = inject(PopupService);

    autosuggestLoading$ = combineLatest([this.autosuggestFacade.autosuggestLoading$, this.autosuggestClosestFacade.autosuggestLoading$]).pipe(
        map((loaders) => loaders.includes(true)),
    );

    @Input()
    formGroupName!: string;

    @Input()
    customerPersonaDetails?: OrderPersonalDetailsValue;

    planningTypes$ = this.dictionariesFacade.planningTypes$;
    bookingTypes = BOOKING_TYPES;
    partners$ = this.selectOptionsFacade.partners$;
    notesOpen = false;
    form!: FormGroup<PassengerCreateFormGroup | PassengerUpdateFormGroup>;

    private readonly subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.form = <FormGroup<PassengerCreateFormGroup | PassengerUpdateFormGroup>>this.controlContainer.control;
        this.subscriptions$.add(
            this.form.controls.departureDate.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    tap((value) => {
                        if (value !== null && value < new Date()) {
                            this.popupService.open(AlertPopupComponent);
                        }
                    }),
                )
                .subscribe(),
        );
        this.notesOpen = Boolean(this.form.value.internalNotes) || Boolean(this.form.value.driverNotes) || Boolean(this.form.value.notes);

        if (this.formIsCreate) {
            this.subscriptions$.add(
                this.formCreate.controls.returnRouteActive.valueChanges
                    .pipe(
                        startWith(this.formCreate.controls.returnRouteActive.getRawValue()),
                        tap((value) => {
                            if (value === true) {
                                this.formCreate.controls.returnRoute.enable();
                                this.formCreate.controls.returnRoute.controls.from.disable();
                                this.formCreate.controls.returnRoute.controls.from.controls.term.enable();
                                this.formCreate.controls.returnRoute.controls.from.controls.country.enable();
                                this.formCreate.controls.returnRoute.controls.to.disable();
                                this.formCreate.controls.returnRoute.controls.to.controls.term.enable();
                                this.formCreate.controls.returnRoute.controls.to.controls.country.enable();
                            } else {
                                this.formCreate.controls.returnRoute.disable();
                            }
                        }),
                    )
                    .subscribe(),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    passengerName() {
        return `${this.form.value.personalDetails?.firstname || ''} ${this.form.value.personalDetails?.surname || ''}`.trim();
    }

    onSwitchAddressClick(event: Event) {
        event.preventDefault();

        // without event
        this.form.patchValue(
            {
                from: this.form.controls.to.getRawValue(),
                to: this.form.controls.from.getRawValue(),
            },
            { emitEvent: false },
        );

        // with event
        this.form.controls.to.patchValue({
            accuracy: this.form.controls.to.controls.accuracy.getRawValue(),
            geoPosition: this.form.controls.to.controls.geoPosition.getRawValue(),
            countryRestricted: this.form.controls.to.controls.countryRestricted.getRawValue(),
        });

        this.form.controls.from.patchValue({
            accuracy: this.form.controls.from.controls.accuracy.getRawValue(),
            geoPosition: this.form.controls.from.controls.geoPosition.getRawValue(),
            countryRestricted: this.form.controls.from.controls.countryRestricted.getRawValue(),
        });
    }

    onSwitchAddressReturnRouteClick(event: Event) {
        if (this.formIsCreate) {
            (<FormGroup<PassengerCreateFormGroup>>this.form).controls.returnRoute.patchValue({
                from: (<FormGroup<PassengerCreateFormGroup>>this.form).controls.returnRoute.controls.to.getRawValue(),
                to: (<FormGroup<PassengerCreateFormGroup>>this.form).controls.returnRoute.controls.from.getRawValue(),
            });
            event.preventDefault();
        }
    }

    get index() {
        return parseInt(this.formGroupName) + 1;
    }

    get formIsCreate(): boolean {
        return this.form.getRawValue().id === null;
    }

    get formCreate(): FormGroup<PassengerCreateFormGroup> {
        return this.form as FormGroup<PassengerCreateFormGroup>;
    }
}
