<div [formGroup]="form" class="row mb-5">
    <div class="col-6">
        <app-form-dropdown
            formControlGroup="partner"
            [options]="(partnersSelect$ | async) || []"
            [block]="true"
            [showClear]="true"
            [label]="'BOOKINGS.FORM.partner' | translate"
            [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
            [appendTo]="'body'"
            [showFilter]="true"
            [virtualScrollWidth]="42"
        ></app-form-dropdown>
    </div>
    <div class="col-3" *ngIf="showTeamNumber$ | async">
        <app-form-input formControlGroup="teamNumber" [label]="'BOOKINGS.FORM.team_number' | translate" [block]="true"></app-form-input>
    </div>
</div>

<div [formGroup]="form">
    <div class="row fs-14">
        <div class="col-6 mb-5">
            <app-form-input formControlGroup="firstName" [block]="true" [label]="'BOOKINGS.FORM.name' | translate"></app-form-input>
        </div>
        <div class="col-6 mb-5">
            <app-form-input formControlGroup="lastName" [block]="true" [label]="'BOOKINGS.FORM.surname' | translate"></app-form-input>
        </div>
        <div class="col-6 mb-5">
            <app-form-input formControlGroup="email" [block]="true" [label]="'BOOKINGS.FORM.email' | translate"></app-form-input>
        </div>
        <div class="col-6 mb-5">
            <app-form-input
                formControlGroup="phone"
                [placeholder]="'+48 000 000 000'"
                [block]="true"
                [label]="'BOOKINGS.FORM.phone' | translate"
            ></app-form-input>
        </div>
    </div>
</div>
