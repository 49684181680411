import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { OrderDetailsAddressValue } from '@app/store/order';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-booking-address-details',
    templateUrl: './booking-address-details.component.html',
    styleUrls: ['./booking-address-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingAddressDetailsComponent {
    @Input() value!: OrderDetailsAddressValue;

    constructor(private translateService: TranslateService) {}

    get street() {
        return [this.value.street?.trim(), this.value.houseNumber?.trim()].join(' ').trim();
    }

    get city() {
        return [this.value.postalCode?.trim(), this.value.city?.trim()].join(' ').trim();
    }

    get country() {
        return this.value.country ? this.translateService.instant(`COUNTRY.${this.value.country}`) : '';
    }
}
