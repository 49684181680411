import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Observable, Subscription, tap } from 'rxjs';
import { DetailsFormGroup } from '../../../../models/form.value';
import { CountriesFacade } from '@app/store/countries';
import { PartnerOptions, SelectOptionsFacade } from '@app/store/select-options';
import { UserFacade } from '@app/modules';
import { Roles } from '@app/modules/permissions';

@Component({
    selector: 'app-form-customer',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit, OnDestroy {
    @Input() isEditMode!: boolean;
    private readonly countriesFacade = inject(CountriesFacade);
    private readonly controlContainer = inject(ControlContainer);
    private readonly selectOptionsFacade = inject(SelectOptionsFacade);
    private readonly userFacade = inject(UserFacade);
    partnersSelect$!: Observable<PartnerOptions[]>;

    @Input() translationScope!: string;

    countriesList$ = this.countriesFacade.availableListTranslated$;
    showTeamNumber$!: Observable<boolean>;

    form!: FormGroup<DetailsFormGroup>;

    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.form = <FormGroup<DetailsFormGroup>>this.controlContainer.control;
        this.partnersSelect$ = this.isEditMode ? this.selectOptionsFacade.partners$ : this.selectOptionsFacade.activePartners$;
        this.subscriptions$.add(
            this.userFacade.user$
                .pipe(
                    tap((user) => {
                        if (user?.role === Roles.ROLE_PARTNER_EMPLOYEE) {
                            this.form.controls.partner.patchValue(user.partner.id);
                            this.form.controls.partner.disable();
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
