import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map, mergeMap, pairwise, toArray } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { HistoryDto } from '../models/history.dto';
import { History } from '../models/history';
import { ItineraryEntityDiff } from '../models/itinirary-diff';
import { ItineraryEntityDto } from '../models/itinirary.dto';
import { ItineraryEntity } from '../models/itinirary';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getHistory(id: string): Observable<History[]> {
        return this.httpClient.get<ApiResponse<HistoryDto>>(`entity-history/full/order/${id}`).pipe(
            map((response) => {
                const responseDataSorted = Object.values(response.data)
                    .flat()
                    .reduce(
                        (prev, next) => {
                            const uuid = `${next.date}-${next.actionUserId ?? ''}-${next.type}`;
                            prev[uuid] ? prev[uuid].push(next) : (prev[uuid] = [next]);
                            return prev;
                        },
                        <HistoryDto>{},
                    );

                return Object.values(responseDataSorted)
                    .map((history) => new History(history))
                    .filter((history) => history.entities.length > 0);
            }),
        );
    }

    getHistoryPlanning(id: string): Observable<ItineraryEntityDiff[]> {
        return this.httpClient.get<ApiResponse<ItineraryEntityDto[]>>(`booking/${id}/history_itineraries`).pipe(
            map((response) => response.data.map((entity) => new ItineraryEntity(entity))),
            mergeMap((data) => from([new ItineraryEntity(), ...data])),
            pairwise(),
            map(([_old, _new]) => new ItineraryEntityDiff(_old, _new)),
            toArray(),
        );
    }
}
