import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { FormValidators } from '@app/shared/forms';
import { GeoPositionModel } from '@app/store/planning/models';
import { OrderPassengerFormCreateGroup, PassengerCreateFormGroup } from '../models/form.value';
import { SERVICE_TYPES } from '@app/store/payment/enums';
import { CURRENCY_TYPES, ITEM_STATUS, OrderModel, OrderBookingPersonModel, PAYMENT_TYPES } from '@app/store/order';
import { CUSTOMER_TYPE } from '@app/store/order/enums/customer-type.enum';
import { Subscription, filter, tap } from 'rxjs';
import { PLANNING_TYPE } from '@app/store/planning/enums';
import { ItemFormGroup, PaymentsCreateFormGroup } from '../../../shared/models/form.value';
import { ACCURACY_TYPES } from '@app/store/order/enums/accuracy.enum';

function requiredIfReturnRouteValidator(formControl: AbstractControl) {
    if (!formControl.parent || !formControl.parent.get('returnRouteActive')) {
        return null;
    }

    if (formControl?.parent?.get('returnRouteActive')?.value) {
        return Validators.required(formControl);
    }
    return null;
}

function planningTypeReturnRouteRequireValidator(formControl: AbstractControl) {
    const formRoot = formControl.parent?.parent;

    if (!formRoot || !formRoot.get('returnRouteActive')) {
        return null;
    }

    if (formRoot.get('returnRouteActive')?.value) {
        return Validators.required(formControl);
    }
    return null;
}

@Injectable()
export class FormService implements OnDestroy {
    form: FormGroup<OrderPassengerFormCreateGroup> = this.formBuilder.group({
        details: this.formBuilder.group({
            firstName: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required, FormValidators.Text] }),
            lastName: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required, FormValidators.Text] }),
            phone: this.formBuilder.control<string | null>(''),
            email: this.formBuilder.control<string | null>('', { validators: [FormValidators.Email] }),
            partner: this.formBuilder.control<string | null>(null, { validators: [FormValidators.Required] }),
            teamNumber: this.formBuilder.control<string | null>(null),
        }),
        invoiceDetails: this.formBuilder.group({
            address: this.formBuilder.group({
                city: this.formBuilder.control<null | string>(''),
                country: this.formBuilder.control<null | string>(''),
                houseNumber: this.formBuilder.control<null | string>(''),
                postalCode: this.formBuilder.control<null | string>(''),
                street: this.formBuilder.control<null | string>(''),
            }),
            isCompany: this.formBuilder.control<boolean | null>(null),
            invoice: this.formBuilder.control<boolean>(false),
            nip: this.formBuilder.control<string | null>('', { validators: [FormValidators.ForeignNip, FormValidators.MaxLength(16)] }),
            companyName: this.formBuilder.control<string | null>(''),
        }),
        passengers: this.formBuilder.array<FormGroup<PassengerCreateFormGroup>>([]),
        transactions: this.formBuilder.array<FormGroup<PaymentsCreateFormGroup>>([
            this.formBuilder.group({
                paymentMethod: this.formBuilder.control<PAYMENT_TYPES | null>(null, { validators: FormValidators.Required }),
                currency: this.formBuilder.control<CURRENCY_TYPES | null>(null, { validators: FormValidators.Required }),
            }),
        ]),
    });

    private readonly subscriptions = new Subscription();

    constructor(private formBuilder: NonNullableFormBuilder) {}

    setValue(order: OrderModel, bookingId: string) {
        this.form.patchValue(
            {
                details: {
                    firstName: order.customer.firstName,
                    lastName: order.customer.lastName,
                    phone: order.customer.phone,
                    email: order.customer.email,

                    partner: order.partner?.id || null,
                    teamNumber: order.teamNumber,
                },
                invoiceDetails: {
                    companyName: order.customer.companyName,
                    nip: order.customer.nip,
                    address: {
                        city: order.customer.address.city,
                        country: order.customer.address.country,
                        houseNumber: order.customer.address.houseNumber,
                        postalCode: order.customer.address.postalCode,
                        street: order.customer.address.street,
                    },
                    isCompany: order.customer.type === CUSTOMER_TYPE.COMPANY ? true : false,
                    invoice: order.invoice,
                },
            },
            { emitEvent: false },
        );

        const booking = order.bookings.find((booking) => booking.id === bookingId) as OrderBookingPersonModel | undefined; // #FIXME

        if (booking && this.form.controls.passengers.controls.length > 0) {
            this.form.controls.passengers.at(0).patchValue(
                {
                    id: null,
                    planningType: booking.planningType,
                    departureDate: booking.route.departureDate,
                    internalNotes: booking.internalNotes,
                    driverNotes: booking.driverNotes,
                    notes: booking.notes,
                    personalDetails: {
                        firstname: booking.passenger.firstName,
                        surname: booking.passenger.lastName,
                        email: booking.passenger.email,
                        phone: booking.passenger.phone,
                    },
                    from: {
                        id: booking.route.from?.term || null,
                        term: booking.route.from?.term || '',
                        isPoint: booking.route.from?.isPoint || false,
                        country: booking.route.from?.country || null,
                        city: booking.route.from?.city || null,
                        street: booking.route.from?.street || null,
                        houseNumber: booking.route.from?.houseNumber || null,
                        postalCode: booking.route.from?.postalCode || null,
                        geoPosition: booking.route.from?.geoPosition || {
                            latitude: null,
                            longitude: null,
                        },
                    },
                    to: {
                        id: booking.route.to?.term || null,
                        term: booking.route.to?.term || '',
                        isPoint: booking.route.to?.isPoint || false,
                        country: booking.route.to?.country || null,
                        city: booking.route.to?.city || null,
                        street: booking.route.to?.street || null,
                        houseNumber: booking.route.to?.houseNumber || null,
                        postalCode: booking.route.to?.postalCode || null,
                        geoPosition: booking.route.to?.geoPosition || {
                            latitude: null,
                            longitude: null,
                        },
                    },
                },
                { emitEvent: false },
            );
            this.form.controls.transactions.at(0).patchValue({
                paymentMethod: order.payments.at(0)?.method,
                currency: order.transactions.at(0)?.currency,
            });

            const item = booking.items.find((item) => item.type === SERVICE_TYPES.RIDE);
            if (item) {
                this.form.controls.passengers.at(0).controls.items.at(0).controls.price.patchValue(item.price, { emitEvent: false });
            }
        }

        // emit event at end
        this.form.patchValue(this.form.getRawValue());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addBooking() {
        const itemsFormGroup = this.formBuilder.array<FormGroup<ItemFormGroup>>([]);
        const itemsFormReturnGroup = this.formBuilder.array<FormGroup<ItemFormGroup>>([]);
        itemsFormGroup.push(
            this.formBuilder.group({
                id: this.formBuilder.control<string | null>(null),
                type: this.formBuilder.control<SERVICE_TYPES | null>({ value: SERVICE_TYPES.RIDE, disabled: true }, { validators: [FormValidators.Required] }),
                price: this.formBuilder.control<number>(0, { validators: [FormValidators.Required] }),
                notes: this.formBuilder.control<string | null>(''),
                name: this.formBuilder.control<string | null>(null),
                bookingId: this.formBuilder.control<string | null>(''),
                status: this.formBuilder.control<ITEM_STATUS | null>(null),
            }),
        );
        itemsFormReturnGroup.push(
            this.formBuilder.group({
                id: this.formBuilder.control<string | null>(null),
                type: this.formBuilder.control<SERVICE_TYPES | null>({ value: SERVICE_TYPES.RIDE, disabled: true }, { validators: [FormValidators.Required] }),
                price: this.formBuilder.control<number>(0, { validators: [FormValidators.Required] }),
                notes: this.formBuilder.control<string | null>(''),
                name: this.formBuilder.control<string | null>(null),
                bookingId: this.formBuilder.control<string | null>(''),
                status: this.formBuilder.control<ITEM_STATUS | null>(null),
            }),
        );

        const passengerFormGroup: FormGroup<PassengerCreateFormGroup> = this.formBuilder.group({
            id: this.formBuilder.control<string | null>(null),
            planningType: this.formBuilder.control<PLANNING_TYPE | null>(null, { validators: [FormValidators.Required] }),
            internalNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
            driverNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
            notes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
            departureDate: this.formBuilder.control<Date | null>(null, { validators: [Validators.required] }),
            didntGo: this.formBuilder.control(false),
            complaint: this.formBuilder.control(false),
            personalDetails: this.formBuilder.group({
                firstname: this.formBuilder.control<string | null>(null, {
                    validators: [FormValidators.Required, FormValidators.Text],
                }),
                surname: this.formBuilder.control<string | null>(null, {
                    validators: [FormValidators.Required, FormValidators.Text],
                }),
                email: this.formBuilder.control<string | null>(null, {
                    validators: [FormValidators.Email],
                }),
                phone: this.formBuilder.control<string | null>(null),
            }),
            from: this.addBookingAddressForm(),
            to: this.addBookingAddressForm(),
            items: itemsFormGroup,
            returnRouteActive: this.formBuilder.control<boolean>(false),
            returnRoute: this.formBuilder.group(
                {
                    planningType: this.formBuilder.control<PLANNING_TYPE | null>(null, { validators: [planningTypeReturnRouteRequireValidator] }),
                    internalNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
                    driverNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
                    departureDate: this.formBuilder.control<Date | null>(null, { validators: [Validators.required] }),
                    notes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
                    from: this.addBookingAddressForm(),
                    to: this.addBookingAddressForm(),
                    items: itemsFormReturnGroup,
                },
                {
                    validators: [requiredIfReturnRouteValidator],
                },
            ),
        });
        this.subscriptions.add(
            this.form.controls.details.controls.partner.valueChanges
                .pipe(
                    tap((value) => {
                        this.form.controls.details.controls.partner.patchValue(value, { emitEvent: false });
                    }),
                )
                .subscribe(),
        );
        this.subscriptions.add(
            passengerFormGroup.controls.returnRouteActive.valueChanges
                .pipe(
                    filter((isReturnRouteActive) => isReturnRouteActive === true),
                    tap(() => {
                        const { from: fromAddress, to: toAddress } = passengerFormGroup.controls;
                        passengerFormGroup.controls.returnRoute.controls.from.setValue({ ...toAddress.getRawValue() });
                        passengerFormGroup.controls.returnRoute.controls.to.setValue({ ...fromAddress.getRawValue() });
                    }),
                )
                .subscribe(),
        );

        this.form.controls.passengers.push(passengerFormGroup);
    }

    removeBooking(index: number) {
        this.form.controls.passengers.removeAt(index);
    }

    private addBookingAddressForm() {
        const form = this.formBuilder.group({
            id: this.formBuilder.control<string | null>(null),
            term: this.formBuilder.control<string>(''),
            isPoint: this.formBuilder.control<boolean>({ value: false, disabled: true }),
            country: this.formBuilder.control<string | null>({ value: null, disabled: false }, { validators: [Validators.required] }),
            countryRestricted: this.formBuilder.control<boolean>({ value: true, disabled: true }),
            city: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            street: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            houseNumber: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            accuracy: this.formBuilder.control<ACCURACY_TYPES>({ value: ACCURACY_TYPES.HOUSE_NUMBER, disabled: true }, { validators: [] }),
            postalCode: this.formBuilder.control<string | null>(
                { value: null, disabled: true },
                {
                    validators: [FormValidators.MaxLength(10)],
                },
            ),
            geoPosition: this.formBuilder.control<GeoPositionModel>({
                latitude: null,
                longitude: null,
            }),
        });

        return form;
    }
}
