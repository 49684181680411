<div [formGroup]="form">
    <div *ngIf="form.value.id !== null" class="mb-4">
        <div class="d-flex align-items-center">
            <h4 class="tw-text-ellipsis tw-mr-auto tw-text-[1.625rem] tw-font-semibold">
                {{ passengerName() || 'Pasażer' }}
            </h4>
            {{ 'BOOKINGS.unique_number' | translate }} <span class="fw-700">{{ (form.getRawValue().id | bookingNumber | async) || '-' }}</span>
        </div>
    </div>

    <app-form-section
        [accordion]="true"
        [alert]="
            (form.controls.planningType.invalid || form.controls.departureDate.invalid) &&
            (form.controls.planningType.touched || form.controls.departureDate.touched)
        "
        [open]="true"
        [divider]="false"
        [title]="'BOOKINGS.FORM.SECTIONS.planning_type' | translate"
    >
        <div class="row">
            <div class="col-md-3 col-6">
                <app-form-dropdown
                    formControlGroup="planningType"
                    [options]="(planningTypes$ | async) ?? []"
                    [block]="true"
                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                />
            </div>
            <div class="col-md-3 col-6">
                <app-form-datepicker formControlGroup="departureDate" [block]="true" placeholder="dd.mm.yyyy" />
                <ng-container *ngIf="form.value.id !== null">
                    <ng-container *ngIf="form.getRawValue().id | bookingDateHistory | async as dateHistory">
                        <div *ngIf="dateHistory.length > 0" class="pt-2 ps-4">
                            <app-services-date-history [translationScope]="'BOOKINGS.'" [dateHistory]="dateHistory"></app-services-date-history>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="form.value.id !== null" class="col-md-6 col-12 text-end">
                <div class="pt-3">
                    <div class="me-5 d-inline-block">
                        <app-form-switch formControlGroup="complaint" label="{{ 'BOOKINGS.FORM.complaint' | translate }}" direction="column"></app-form-switch>
                    </div>
                </div>
            </div>
        </div>
    </app-form-section>

    <app-form-section
        [accordion]="true"
        [alert]="form.controls.personalDetails.invalid && form.controls.personalDetails.touched"
        [open]="true"
        [title]="'BOOKINGS.FORM.SECTIONS.passenger_details' | translate"
    >
        <app-form-personal-details
            formControlGroup="personalDetails"
            [translationScope]="'BOOKINGS.'"
            [customerPersonaDetails]="customerPersonaDetails"
        ></app-form-personal-details>
    </app-form-section>

    <app-form-section
        [accordion]="true"
        [alert]="(form.controls.from.invalid || form.controls.to.invalid) && (form.controls.from.touched || form.controls.to.touched)"
        [open]="true"
        [title]="'BOOKINGS.FORM.SECTIONS.departure-and-destination' | translate"
    >
        <div class="d-flex">
            <div [style.width.%]="46">
                <app-form-autosuggest formControlGroup="from">
                    <app-address-preview
                        *ngIf="form.controls.id.getRawValue() !== null"
                        preview
                        [bookingId]="form.controls.id.getRawValue()"
                        direction="from"
                    />
                </app-form-autosuggest>
            </div>

            <div [style.width.%]="8" class="align-self-center text-center">
                <button
                    [disabled]="(autosuggestLoading$ | async) === true ? true : null"
                    class="btn btn-round px-5 btn-success"
                    (click)="onSwitchAddressClick($event)"
                >
                    <span appIcon="arrow-left-right" color="white" size="6"></span>
                </button>
            </div>

            <div [style.width.%]="46">
                <app-form-autosuggest formControlGroup="to">
                    <app-address-preview *ngIf="form.controls.id.getRawValue() !== null" preview [bookingId]="form.controls.id.getRawValue()" direction="to" />
                </app-form-autosuggest>
            </div>
        </div>
    </app-form-section>

    <ng-container *ngIf="formIsCreate">
        <app-form-section
            [accordion]="true"
            [alert]="formCreate.controls.items.invalid && formCreate.controls.items.touched"
            [open]="true"
            [title]="'BOOKINGS.FORM.SECTIONS.services_payment' | translate"
        >
            <app-payments-add-control formGroupName="items" [type]="bookingTypes.PASSENGER" [disableRideOption]="true"></app-payments-add-control>
        </app-form-section>
    </ng-container>

    <app-form-section
        [accordion]="true"
        [alert]="
            (form.controls.internalNotes.invalid || form.controls.driverNotes.invalid) &&
            (form.controls.internalNotes.touched || form.controls.driverNotes.touched)
        "
        [open]="notesOpen"
        [title]="'BOOKINGS.FORM.SECTIONS.misc' | translate"
    >
        <div class="mb-4">
            <div class="mb-4">
                <app-form-textarea formControlGroup="notes" [label]="'BOOKINGS.FORM.notes' | translate" [block]="true" />
            </div>
            <div class="mb-4">
                <app-form-textarea formControlGroup="driverNotes" [label]="'BOOKINGS.FORM.driver_notes' | translate" [block]="true" />
            </div>
            <app-form-textarea formControlGroup="internalNotes" [label]="'BOOKINGS.FORM.internal_notes' | translate" [block]="true" />
        </div>
    </app-form-section>

    <ng-container *ngIf="formIsCreate">
        <div class="pt-8"></div>

        <div class="divider py-4">
            <div class="bg-dark rounded" [style.height.rem]="0.125"></div>
        </div>

        <div class="d-flex align-items-center pt-8">
            <span class="pin pin-secondary pin-lg fs-24 me-4">{{ index }}</span>
            <h4 class="mb-0 tw-text-secondary tw-font-semibold text-oneline tw-text-[1.25rem] me-4">{{ passengerName() || 'Pasażer' }} - Powrót</h4>
            <div class="ms-auto">
                <app-form-switch formControlGroup="returnRouteActive" direction="column"></app-form-switch>
            </div>
        </div>

        <div @Grow *ngIf="formCreate.controls.returnRouteActive?.value === true">
            <div class="pt-4 mt-12 border-top">
                <app-form-section [accordion]="true" [divider]="false" [title]="'BOOKINGS.FORM.SECTIONS.planning_type' | translate" formGroupName="returnRoute">
                    <div class="row">
                        <div class="col-md-3 col-6">
                            <app-form-dropdown
                                formControlGroup="planningType"
                                [options]="(planningTypes$ | async) ?? []"
                                [block]="true"
                                [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                            />
                        </div>
                        <div class="col-md-3 col-6">
                            <app-form-datepicker formControlGroup="departureDate" [block]="true" placeholder="dd.mm.yyyy" />
                            <ng-container *ngIf="form.value.id !== null">
                                <ng-container *ngIf="form.getRawValue().id | bookingDateHistory | async as dateHistory">
                                    <div *ngIf="dateHistory.length > 0" class="pt-2 ps-4">
                                        <app-services-date-history [translationScope]="'BOOKINGS.'" [dateHistory]="dateHistory"></app-services-date-history>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </app-form-section>

                <div class="pt-10">
                    <app-form-section [accordion]="true" [open]="true" [title]="'BOOKINGS.FORM.SECTIONS.departure-and-destination' | translate">
                        <div class="d-flex" formGroupName="returnRoute">
                            <div class="flex-grow-1">
                                <app-form-autosuggest formControlGroup="from"></app-form-autosuggest>
                            </div>
                            <div class="mx-7 align-self-center">
                                <a href="#" class="btn btn-round px-5 btn-success" (click)="onSwitchAddressReturnRouteClick($event)">
                                    <span appIcon="arrow-left-right" color="white" size="6"></span>
                                </a>
                            </div>
                            <div class="flex-grow-1">
                                <app-form-autosuggest formControlGroup="to"></app-form-autosuggest>
                            </div>
                        </div>
                    </app-form-section>

                    <app-form-section [accordion]="true" [title]="'BOOKINGS.FORM.SECTIONS.services_payment' | translate" formGroupName="returnRoute">
                        <app-payments-add-control formGroupName="items" [type]="bookingTypes.PASSENGER" [disableRideOption]="true"></app-payments-add-control>
                    </app-form-section>

                    <app-form-section [accordion]="true" [title]="'BOOKINGS.FORM.SECTIONS.misc' | translate" formGroupName="returnRoute">
                        <div class="pb-10">
                            <app-form-textarea formControlGroup="notes" [label]="'BOOKINGS.FORM.notes' | translate" [block]="true" />
                        </div>
                        <div class="mb-4">
                            <app-form-textarea formControlGroup="driverNotes" [label]="'BOOKINGS.FORM.driver_notes' | translate" [block]="true" />
                        </div>
                        <div class="mb-4">
                            <app-form-textarea formControlGroup="internalNotes" [label]="'BOOKINGS.FORM.internal_notes' | translate" [block]="true" />
                        </div>
                    </app-form-section>
                </div>
            </div>
        </div>
    </ng-container>
</div>
