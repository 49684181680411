<ng-container *ngIf="value">
    <div class="h-100">
        <div class="d-flex flex-column fs-16 h-100">
            <div>
                <div class="fw-700 mb-4">{{ name || '-' }}</div>
                <div>{{ value.phone || '-' }}</div>
                <div>{{ value.email || '-' }}</div>
            </div>
        </div>
    </div>
</ng-container>
