<div [style.width.rem]="innerWidth" class="inner relative bg-lightest relative">
    <div #divBlock class="ps scroll">
        <div pFocusTrap>
            <h2 class="tw-mb-4 tw-text-[2rem]">{{ 'ORDER.booking' | translate }}</h2>
            <app-box [border]="true" *ngIf="bookingId">
                <form appScrollToInvalidInput [ps]="ps" *ngIf="form !== undefined" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="d-flex align-items-center mb-8">
                        <h4 class="tw-text-ellipsis tw-mr-auto tw-text-[1.625rem] tw-font-semibold">
                            {{ passengerName() || 'Pasażer' }}
                        </h4>
                        {{ 'ORDER.BOOKING_DETAILS.unique_number' | translate }} <span class="fw-700">{{ (booking$ | async)?.externalId || '-' }}</span>
                    </div>

                    <app-form-section [title]="'BOOKINGS.FORM.SECTIONS.planning_type' | translate">
                        <div class="d-flex justify-content-between px-2">
                            <div class="d-flex">
                                <app-form-dropdown
                                    formControlGroup="planningType"
                                    [options]="(planningTypes$ | async) ?? []"
                                    [block]="true"
                                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                                />
                                <div class="mx-3">
                                    <app-form-datepicker formControlGroup="departureDate" [block]="true" placeholder="dd.mm.yyyy" />
                                    <ng-container *ngIf="booking$ | async as booking">
                                        <app-services-date-history
                                            *ngIf="booking.dateHistory.length"
                                            [translationScope]="'BOOKINGS.'"
                                            [dateHistory]="booking.dateHistory"
                                        />
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </app-form-section>

                    <app-form-section [title]="'BOOKINGS.FORM.SECTIONS.passenger_details' | translate">
                        <app-form-personal-details formControlGroup="personalDetails" [translationScope]="'BOOKINGS.'"></app-form-personal-details>
                    </app-form-section>

                    <app-form-section [title]="'BOOKINGS.FORM.SECTIONS.departure-and-destination' | translate">
                        <div class="d-flex">
                            <div [style.width.%]="46">
                                <app-form-autosuggest formControlGroup="from">
                                    <app-address-preview
                                        *ngIf="form.controls.id.getRawValue() !== null"
                                        preview
                                        [bookingId]="form.controls.id.getRawValue()"
                                        direction="from"
                                    />
                                </app-form-autosuggest>
                            </div>
                            <div [style.width.%]="8" class="align-self-center text-center">
                                <button
                                    [disabled]="(autosuggestLoading$ | async) === true ? true : null"
                                    class="btn btn-round px-5 btn-success"
                                    (click)="onSwitchAddressClick($event)"
                                >
                                    <span appIcon="arrow-left-right" color="white" size="6"></span>
                                </button>
                            </div>
                            <div [style.width.%]="46">
                                <app-form-autosuggest formControlGroup="to">
                                    <app-address-preview
                                        *ngIf="form.controls.id.getRawValue() !== null"
                                        preview
                                        [bookingId]="form.controls.id.getRawValue()"
                                        direction="to"
                                    />
                                </app-form-autosuggest>
                            </div>
                        </div>
                    </app-form-section>

                    <app-form-section [title]="'BOOKINGS.FORM.SECTIONS.misc' | translate">
                        <div class="mb-4">
                            <app-form-textarea formControlGroup="notes" [label]="'BOOKINGS.FORM.notes' | translate" [block]="true" />
                        </div>
                        <div class="mb-4">
                            <app-form-textarea formControlGroup="driverNotes" [label]="'BOOKINGS.FORM.driver_notes' | translate" [block]="true" />
                        </div>
                        <div class="mb-4">
                            <app-form-textarea formControlGroup="internalNotes" [label]="'BOOKINGS.FORM.internal_notes' | translate" [block]="true" />
                        </div>
                    </app-form-section>

                    <div class="mb-12">
                        <app-form-didntgo-reason [formGroup]="form" />
                    </div>

                    <div class="d-flex justify-content-end">
                        <p-button
                            label="{{ 'BUTTONS.cancel' | translate }}"
                            icon="pi pi-times"
                            class="p-button-secondary me-2"
                            (click)="onCancelClick($event)"
                        />
                        <p-button
                            type="submit"
                            [disabled]="(autosuggestLoading$ | async) === true ? true : false"
                            label="{{ 'BUTTONS.add-changes' | translate }}"
                            icon="pi pi-check"
                            class="p-button-secondary"
                        />
                    </div>
                </form>
            </app-box>
        </div>
    </div>
</div>
