import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Itinerary } from '@app/store/models';

@Component({
    selector: 'app-itinerary-details',
    templateUrl: './itinerary-details.component.html',
    styleUrls: ['./itinerary-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryDetailsComponent {
    @Input() itineraries!: Itinerary[];
}
