import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { FormValidators } from '@app/shared/forms';
import { Observable, combineLatest, filter, map, startWith, take, tap } from 'rxjs';
import { FormService } from '../../services/form-edit.service';
import { SidebarBaseComponent } from '@app/shell/sidebar/components/sidebar/sidebar.component';
import { ActivatedRoute, Router, createUrlTreeFromSnapshot } from '@angular/router';
import { OrderBookingPersonModel, OrderFacade } from '@app/store/order';
import { GeoPositionModel } from '@app/store/planning/models';
import { PLANNING_TYPE } from '@app/store/planning/enums';
import { Confirmable } from '@app/shared/decorators/confirmable.decorator';
import { ACCURACY_TYPES } from '@app/store/order/enums/accuracy.enum';
import { AutosuggestClosestFacade, AutosuggestFacade } from '@app/store/heremaps';
import { DictionariesFacade } from '@app/dictionaries/store/facades/dictionaries.facade';
import { SelectOptionsFacade } from '@app/store/select-options';

@Component({
    selector: 'app-edit-booking',
    templateUrl: './edit-booking.component.html',
    styleUrls: ['./edit-booking.component.scss', '../../../../../shell/sidebar/styles/sidebar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBookingComponent extends SidebarBaseComponent implements OnInit, OnDestroy {
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private orderFacade = inject(OrderFacade);
    private formService = inject(FormService);
    private formBuilder = inject(NonNullableFormBuilder);
    private autosuggestFacade = inject(AutosuggestFacade);
    private autosuggestClosestFacade = inject(AutosuggestClosestFacade);
    private dictionariesFacade = inject(DictionariesFacade);
    private selectOptionsFacade = inject(SelectOptionsFacade);
    bookingId = this.activatedRoute.snapshot.firstChild?.paramMap.get('booking-edit') || null;
    partners$ = this.selectOptionsFacade.partners$;
    planningTypes$ = this.dictionariesFacade.planningTypes$;
    order$ = this.orderFacade.order$;
    booking$!: Observable<OrderBookingPersonModel>;

    autosuggestLoading$ = combineLatest([this.autosuggestFacade.autosuggestLoading$, this.autosuggestClosestFacade.autosuggestLoading$]).pipe(
        map((loaders) => loaders.includes(true)),
    );

    skipConfirm = false;

    form = this.formBuilder.group({
        id: this.formBuilder.control<string | null>(null),
        planningType: this.formBuilder.control<PLANNING_TYPE | null>(null, { validators: [FormValidators.Required] }),
        internalNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
        notes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
        driverNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
        departureDate: this.formBuilder.control<Date | null>(null, { validators: [Validators.required] }),
        didntGo: this.formBuilder.control(false),
        didntGoReason: this.formBuilder.control<number | null>(null),
        personalDetails: this.formBuilder.group({
            firstname: this.formBuilder.control<string | null>(null, {
                validators: [FormValidators.Required, FormValidators.Text],
            }),
            surname: this.formBuilder.control<string | null>(null, {
                validators: [FormValidators.Required, FormValidators.Text],
            }),
            email: this.formBuilder.control<string | null>(null, {
                validators: [FormValidators.Email],
            }),
            phone: this.formBuilder.control<string | null>(null),
        }),
        from: this.formBuilder.group({
            id: this.formBuilder.control<string | null>(null),
            term: this.formBuilder.control<string>(''),
            isPoint: this.formBuilder.control(false),
            country: this.formBuilder.control<string | null>({ value: null, disabled: false }, { validators: [Validators.required] }),
            countryRestricted: this.formBuilder.control<boolean>({ value: true, disabled: true }),
            city: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            street: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            houseNumber: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            accuracy: this.formBuilder.control<ACCURACY_TYPES>({ value: ACCURACY_TYPES.HOUSE_NUMBER, disabled: true }, { validators: [] }),
            postalCode: this.formBuilder.control<string | null>(
                { value: null, disabled: true },
                {
                    validators: [FormValidators.MaxLength(10)],
                },
            ),
            geoPosition: this.formBuilder.control<GeoPositionModel>({
                latitude: null,
                longitude: null,
            }),
        }),
        to: this.formBuilder.group({
            id: this.formBuilder.control<string | null>(null),
            term: this.formBuilder.control<string>(''),
            isPoint: this.formBuilder.control(false),
            country: this.formBuilder.control<string | null>({ value: null, disabled: false }, { validators: [Validators.required] }),
            countryRestricted: this.formBuilder.control<boolean>({ value: true, disabled: true }),
            city: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            street: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            houseNumber: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            accuracy: this.formBuilder.control<ACCURACY_TYPES>({ value: ACCURACY_TYPES.HOUSE_NUMBER, disabled: true }, { validators: [] }),
            postalCode: this.formBuilder.control<string | null>(
                { value: null, disabled: true },
                {
                    validators: [FormValidators.MaxLength(10)],
                },
            ),
            geoPosition: this.formBuilder.control<GeoPositionModel>({
                latitude: null,
                longitude: null,
            }),
        }),
    });

    get touched() {
        return this.form.touched;
    }

    ngOnInit(): void {
        this.booking$ = this.order$.pipe(
            map((order) => order.bookings.find((booking) => booking.id === this.bookingId)),
            filter((booking): booking is OrderBookingPersonModel => booking !== undefined),
        );

        this.subscriptions$.add(
            this.formService.form.controls.passengers.valueChanges
                .pipe(
                    startWith(this.formService.form.controls.passengers.getRawValue()),
                    map(() => this.formService.form.controls.passengers.getRawValue().findIndex((value) => value.id === this.bookingId)),
                    filter((index) => index !== -1),
                    take(1),
                    tap((index) => {
                        this.form.patchValue(this.formService.form.controls.passengers.at(index).getRawValue());
                    }),
                )
                .subscribe(),
        );
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    onCancelClick(event: MouseEvent) {
        event.preventDefault();
        this.goBack();
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const index = this.formService.form.controls.passengers.value.findIndex((value) => value.id === this.bookingId);
            if (index !== -1) {
                this.formService.form.controls.passengers.at(index).patchValue(this.form.getRawValue());
                this.formService.form.controls.passengers.at(index).markAsTouched();
            }
            this.skipConfirm = true;
            this.goBack();
        }
    }

    onSwitchAddressClick(event: Event) {
        event.preventDefault();

        // without event
        this.form.patchValue(
            {
                from: this.form.controls.to.getRawValue(),
                to: this.form.controls.from.getRawValue(),
            },
            { emitEvent: false },
        );

        // with event
        this.form.controls.to.patchValue({
            accuracy: this.form.controls.to.controls.accuracy.getRawValue(),
            geoPosition: this.form.controls.to.controls.geoPosition.getRawValue(),
            countryRestricted: this.form.controls.to.controls.countryRestricted.getRawValue(),
        });

        this.form.controls.from.patchValue({
            accuracy: this.form.controls.from.controls.accuracy.getRawValue(),
            geoPosition: this.form.controls.from.controls.geoPosition.getRawValue(),
            countryRestricted: this.form.controls.from.controls.countryRestricted.getRawValue(),
        });
    }

    passengerName() {
        return `${this.form.value.personalDetails?.firstname || ''} ${this.form.value.personalDetails?.surname || ''}`.trim();
    }

    @Confirmable({ messageKey: 'MODAL.exit_without_save', confirmKey: 'MODAL.yes', cancelKey: 'MODAL.no' })
    close() {
        this.skipConfirm = true;
        this.goBack();
    }

    private goBack() {
        const tree = createUrlTreeFromSnapshot(this.activatedRoute.snapshot, []);
        const params = { ...tree.root.children['primary'].segments[0].parameters };
        delete params['booking-edit'];
        tree.root.children['primary'].segments[0].parameters = params;
        this.router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
    }
}
