import { ParcelSizeType } from '../enums/parcel-size.enum';

export const parcelSizes: { label: string; value: string }[] = [
    {
        label: 'DICTIONARIES.PARCEL_SIZE.small',
        value: ParcelSizeType.SMALL,
    },
    {
        label: 'DICTIONARIES.PARCEL_SIZE.medium',
        value: ParcelSizeType.MEDIUM,
    },
    {
        label: 'DICTIONARIES.PARCEL_SIZE.large',
        value: ParcelSizeType.LARGE,
    },
];
