<p-accordion>
    <p-accordionTab [header]="'SERVICES.HISTORY.ENTITY.' + entity.type | translate">
        <div class="row align-self-stretch py-2" *ngFor="let diff of entity.differences; let last = last" [class.border-bottom]="!last">
            <div class="col-4">
                <div class="fw-500">{{ 'SERVICES.HISTORY.PROP.' + diff.prop | translate }}:</div>
            </div>
            <div class="col-4">
                <div>{{ diff.old | diffValue: diff.prop }}</div>
            </div>
            <div class="col-4 overflow-auto">
                <div>{{ diff.new | diffValue: diff.prop }}</div>
            </div>
        </div>
    </p-accordionTab>
</p-accordion>
