<div class="mb-12 mt-3" *ngIf="isInvoiceMethod === false; else invoiceMethodBlock">
    <div class="box border rounded p-12 bg-white position-relative">
        <div [formGroup]="form">
            <div class="col-md-2 flex-column align-self-center" [ngClass]="{ 'mb-4': (this.form.controls.invoice.value === true) === true }">
                <app-form-switch formControlGroup="invoice" label="Faktura"></app-form-switch>
            </div>

            <div [ngStyle]="{ display: this.form.controls.invoice.value === true ? 'block' : 'none' }" class="col-md-12">
                <div class="row">
                    <div class="col-12">
                        <app-form-personal-address formControlGroup="address" [translationScope]="'BOOKINGS.'"></app-form-personal-address>
                    </div>
                </div>

                <div class="col-md-2 flex-column align-self-center" [ngClass]="{ 'mb-4': this.form.controls.isCompany.value === true }">
                    <app-form-switch formControlGroup="isCompany" [label]="'Firma'"></app-form-switch>
                </div>

                <div [ngStyle]="{ display: this.form.controls.isCompany.value === true ? 'block' : 'none' }" class="col-md-12">
                    <div class="col-12 h-100">
                        <div class="row company-row">
                            <div class="col-6">
                                <app-form-input formControlGroup="companyName" [block]="true" [label]="'ORDER.FORM.companyName' | translate"></app-form-input>
                            </div>
                            <div class="col-6">
                                <app-form-dropdown
                                    [editable]="true"
                                    [options]="(nips$ | async)!"
                                    formControlGroup="nip"
                                    [block]="true"
                                    [label]="'ORDER.FORM.nip' | translate"
                                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                                ></app-form-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #invoiceMethodBlock>
    <div class="mb-12 mt-3">
        <div class="box border rounded p-12 bg-white position-relative">
            <div [formGroup]="form">
                <div class="col-md-2 flex-column align-self-center" [ngClass]="{ 'mb-4': (this.form.controls.invoice.value === true) === true }">
                    <app-form-switch formControlGroup="invoice" label="Faktura"></app-form-switch>
                </div>

                <div [ngStyle]="{ display: this.form.controls.invoice.value === true ? 'block' : 'none' }" class="col-md-12">
                    <div class="col-12 h-100">
                        <div class="col-6">
                            <app-form-dropdown
                                [editable]="true"
                                [options]="(nips$ | async)!"
                                formControlGroup="nip"
                                [block]="true"
                                [label]="'ORDER.FORM.nip' | translate"
                                [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                            ></app-form-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
