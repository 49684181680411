<div class="d-flex align-items-center" [formGroup]="form">
    <div class="form-control me-4">
        <app-form-dropdown
            formControlGroup="type"
            [options]="(servicesTypes$ | async) || []"
            [label]="index === 0 ? 'Nazwa' : ''"
            [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
            [block]="true"
            [showErrors]="false"
        />
    </div>
    <div class="flex-grow-1 me-4">
        <app-form-input formControlGroup="notes" [label]="index === 0 ? 'Uwagi' : ''" [block]="true" />
    </div>
    <div class="form-control me-10">
        <app-form-input-number
            formControlGroup="price"
            [label]="index === 0 ? 'Kwota' : ''"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            [block]="true"
            [showErrors]="false"
        />
    </div>
    <div [class.pt-7]="index === 0">
        <span *ngIf="form.controls.type.disabled" appIcon="trash" color="light" size="6"></span>
        <span
            *ngIf="!form.controls.type.disabled"
            class="pointer"
            appIcon="trash"
            color="danger"
            size="6"
            (click)="onDeleteClick($event)"
            (keypress)="onDeleteClick($event)"
            tabindex="1"
        ></span>
    </div>
</div>
