import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { BehaviorSubject, Subscription, map, startWith, tap } from 'rxjs';
import { PartnersFacade } from '@app/store/partners-temp';
import { combineLatest } from 'rxjs';
import { InvoiceDetailsFormGroup } from '@app/services/shared/models/form.value';

@Component({
    selector: 'app-form-invoice',
    templateUrl: './transaction-invoice.component.html',
    styleUrls: ['./transaction-invoice.component.scss'],
})
export class TransactionInvoiceComponent implements OnInit, OnDestroy {
    @Input() partner!: FormControl;
    @Input() isInvoiceMethod!: boolean | null;

    private readonly partnersFacade = inject(PartnersFacade);
    private readonly controlContainer = inject(ControlContainer);

    partners$ = this.partnersFacade.partners$;
    form!: FormGroup<InvoiceDetailsFormGroup>;

    nips$ = new BehaviorSubject<SelectOptions[] | null>(null);

    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.form = <FormGroup<InvoiceDetailsFormGroup>>this.controlContainer.control;

        this.subscriptions$.add(
            this.form.controls.isCompany.valueChanges
                .pipe(
                    tap((value) => {
                        if (value === false) {
                            this.form.controls.nip.disable();
                            this.form.controls.companyName.disable();
                        } else {
                            this.form.controls.nip.enable();
                            this.form.controls.companyName.enable();
                        }
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            combineLatest([this.partners$, this.partner.valueChanges.pipe(startWith(this.partner.value))])
                .pipe(
                    map(([partners, formValuePartner]) => {
                        const partner = partners.find((p) => p.id === formValuePartner);

                        const nips = partner?.favouriteNips || [];
                        const nipList = [...nips];

                        if (partner && partner.nip && !nipList.includes(partner.nip)) {
                            nipList.push(partner.nip);
                        }
                        const selectNips = Object.values(nipList).map((nip) => ({
                            label: nip,
                            value: nip,
                        }));

                        this.nips$.next(selectNips);
                        return selectNips;
                    }),
                    tap((value) => {
                        if (this.partner.dirty) {
                            if (value.length === 1) {
                                this.form.controls.nip.patchValue(
                                    value
                                        .map((value) => {
                                            return value.value;
                                        })
                                        .toString(),
                                );
                            } else {
                                this.form.controls.nip.reset();
                            }
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
