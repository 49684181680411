import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ItemFormGroup } from '@app/services/shared/models/form.value';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { PAYMENT_PARCEL_SERVICE_TYPES, PAYMENT_PASSENGER_SERVICE_TYPES, SERVICE_TYPES } from '@app/store/payment/enums';
import { BOOKING_TYPES } from '@app/store/planning/enums';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, map, startWith } from 'rxjs';

@Component({
    selector: 'app-payments-add-item-control',
    templateUrl: './payments-add-item-control.component.html',
    styleUrls: ['payments-add-item-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentsAddItemControlComponent implements OnInit, OnDestroy {
    @Input() type!: BOOKING_TYPES;
    @Input() formGroupName!: number;
    @Input() disableRideOption!: boolean;
    @Output() deleteItem = new EventEmitter<number>();

    form!: FormGroup<ItemFormGroup>;
    servicesTypes$!: Observable<SelectOptions[]>;

    private subscriptions$ = new Subscription();

    constructor(
        private readonly controlContainer: ControlContainer,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.form = <FormGroup<ItemFormGroup>>this.controlContainer.control;
        this.servicesTypes$ = this.form.controls.type.valueChanges.pipe(
            startWith(this.form.controls.type.getRawValue()),
            map(() => this.form.controls.type.getRawValue()),
            map((value) => {
                const types = this.type === BOOKING_TYPES.PASSENGER ? PAYMENT_PASSENGER_SERVICE_TYPES : PAYMENT_PARCEL_SERVICE_TYPES;
                const serviceTypes = Object.values(types)
                    .filter((v) => typeof v === 'number')
                    .filter((v) => {
                        if (v === SERVICE_TYPES.RIDE) {
                            if (value === SERVICE_TYPES.RIDE || !this.disableRideOption) {
                                return true;
                            }
                            return false;
                        }
                        return true;
                    })
                    .map((type) => ({ label: <string>this.translateService.instant(`payment.service-type.${type.toString()}.label`), value: type }));

                return serviceTypes;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onDeleteClick(event: MouseEvent | KeyboardEvent) {
        event.preventDefault();
        this.deleteItem.next(this.index);
    }

    get index() {
        return this.formGroupName;
    }
}
