<div [style.width.rem]="innerWidth" class="inner relative bg-lightest relative">
    <div #divBlock class="ps scroll">
        <div pFocusTrap>
            <div class="d-flex justify-content-between">
                <h2 class="tw-mb-4 tw-text-[2rem]">{{ 'BOOKINGS.service_history' | translate }}</h2>
                <span (click)="closeClick()" (keypress)="closeClick()" tabindex="1" class="pointer ms-8" appIcon="x" color="danger" size="12"></span>
            </div>
            <div class="mb-8 d-flex align-items-center gap-4 ms-4">
                <div class="fs-20 me-4">Filtruj:</div>
                <a
                    href="#"
                    appTooltip="Nowe wpisy"
                    (click)="toggleFilter($event, 'insert')"
                    class="icon-action border-{{ filterForm.controls.insert.value === true ? 'success' : 'light' }} pointer"
                >
                    <span appIcon="plus-lg" [color]="filterForm.controls.insert.value === true ? 'success' : 'light'" size="6"></span>
                </a>
                <a
                    href="#"
                    appTooltip="Aktualizowane wpisy"
                    (click)="toggleFilter($event, 'update')"
                    class="icon-action border-{{ filterForm.controls.update.value === true ? 'info' : 'light' }} pointer"
                >
                    <span appIcon="arrow-repeat" [color]="filterForm.controls.update.value === true ? 'info' : 'light'" size="6"></span>
                </a>
                <a
                    href="#"
                    appTooltip="Usunięte wpisy"
                    (click)="toggleFilter($event, 'remove')"
                    class="icon-action border-{{ filterForm.controls.remove.value === true ? 'danger' : 'light' }} pointer"
                >
                    <span appIcon="x-lg" [color]="filterForm.controls.remove.value === true ? 'danger' : 'light'" size="6"></span>
                </a>
                <a
                    href="#"
                    appTooltip="Planowanie"
                    (click)="toggleFilter($event, 'planning')"
                    class="icon-action border-{{ filterForm.controls.planning.value === true ? 'secondary' : 'light' }} pointer"
                >
                    <span appIcon="map" [color]="filterForm.controls.planning.value === true ? 'secondary' : 'light'" size="5"></span>
                </a>
            </div>
            <div>
                <div *ngFor="let item of all$ | async; let last = last">
                    <app-itinirary-entry *ngIf="isItinerary(item) as item" [itinirary]="item" [last]="last" />
                    <app-history-entry *ngIf="isHistory(item) as item" [history]="item" [last]="last" />
                </div>
            </div>
        </div>
    </div>
</div>
