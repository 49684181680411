<div class="d-inline-flex flex-wrap align-items-center px-10 pb-6 grid">
    <ng-container *ngFor="let itinerary of itineraries; let first = first; let last = last">
        <div class="point point-start" [class.line]="!first" [class.first]="first" *ngIf="itinerary.start">
            <span class="address-top" [appTooltip]="itinerary.start.address?.full ?? ''">{{ itinerary.start.address?.full ?? '-' }}</span>
            <span class="point-icon" [appIcon]="itinerary.start.type === 'start' ? 'box-arrow-in-right' : 'arrow-repeat'" color="default" size="6"></span>
        </div>

        <app-itinerary-tour [itinerary]="itinerary" />

        <div class="point point-stop line" *ngIf="last">
            <span class="address-top" [appTooltip]="itinerary.stop.address?.full ?? ''">{{ itinerary.stop.address?.full ?? '-' }}</span>
            <span class="point-icon" [appIcon]="itinerary.stop.type === 'stop' ? 'box-arrow-in-right' : 'arrow-repeat'" color="default" size="6"></span>
        </div>
    </ng-container>
</div>
