<div class="d-flex flex-wrap align-items-center px-14 pb-8 pt-12 border-top grid">
    <ng-container *ngFor="let itinerary of entity.itineraries; let first = first; let last = last">
        <div class="point point-start border-{{ severity(itinerary.start.address.full.status) }}" [class.line]="!first" [class.first]="first">
            <span
                class="address-top item-{{ severity(itinerary.start.address.full.status) }}"
                [appTooltip]="(itinerary.start.address.full.status === 'removed' ? itinerary.start.address.full.old : itinerary.start.address.full.new) ?? '-'"
            >
                {{ (itinerary.start.address.full.status === 'removed' ? itinerary.start.address.full.old : itinerary.start.address.full.new) ?? '-' }}
            </span>
            <span
                class="point-icon"
                [appIcon]="itinerary.start.type.new === 'start' ? 'box-arrow-in-right' : 'arrow-repeat'"
                [color]="severity(itinerary.start.address.full.status)"
                size="6"
            ></span>
        </div>

        <app-itinirary-tour [itinerary]="itinerary" [first]="first" [last]="last" />

        <div *ngIf="last" class="point point-stop line border-{{ severity(itinerary.stop.address.full.status) }}">
            <span
                class="address-top item-{{ severity(itinerary.stop.address.full.status) }}"
                [appTooltip]="(itinerary.stop.address.full.status === 'removed' ? itinerary.stop.address.full.old : itinerary.stop.address.full.new) ?? '-'"
            >
                {{ (itinerary.stop.address.full.status === 'removed' ? itinerary.stop.address.full.old : itinerary.stop.address.full.new) ?? '-' }}
            </span>
            <span
                class="point-icon"
                [appIcon]="itinerary.stop.type.new === 'stop' ? 'box-arrow-right' : 'arrow-repeat'"
                [color]="severity(itinerary.stop.address.full.status)"
                size="6"
            ></span>
        </div>
    </ng-container>
</div>
