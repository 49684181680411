import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlContainer, FormGroup } from '@angular/forms';

import { AddressFormGroup } from '../../models/personal-address.form.value';
import { CountriesFacade } from '@app/store/countries';

@Component({
    selector: 'app-form-personal-address',
    styleUrls: ['./form-personal-address.component.scss'],
    templateUrl: './form-personal-address.component.html',
})
export class FormPersonalAddressComponent implements OnInit, OnDestroy {
    private countriesFacade = inject(CountriesFacade);
    private controlContainer = inject(ControlContainer);

    @Input() translationScope!: string;
    @Input() formControlGroup!: string;

    countryList$ = this.countriesFacade.availableListTranslated$;

    control!: FormGroup<AddressFormGroup>;

    private readonly subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.control = <FormGroup<AddressFormGroup>>(<FormGroup<AddressFormGroup>>this.controlContainer.control).get(this.formControlGroup);
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
