<div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" class="tour rounded line">
    <div class="d-flex flex-column gap-2 p-4">
        <div class="d-flex align-items-center justify-content-between mb-2">
            <span>{{ itinerary.tour.startTime ?? '--:--' }}</span>
            <span>{{ itinerary.tour.stopTime ?? '--:--' }}</span>
        </div>

        <div class="d-flex align-items-center">
            <span class="me-2" appIcon="car-front" color="car" size="5"></span>
            <span>{{ itinerary.tour.vehicle ?? '-' }}</span>
        </div>

        <div class="d-flex align-items-center">
            <span class="me-2" appIcon="person-vcard" color="car" size="5"></span>
            <span>{{ itinerary.tour.driver ?? '-' }}</span>
        </div>
    </div>

    <div [style.height.rem]="0">
        <div class="phones" [@openClose]="hover$ | async">
            <div class="px-4 pb-4">
                <div
                    class="d-flex align-items-center"
                    *ngFor="let phone of itinerary.tour.phones; let last = last; let first = first"
                    [class.pb-2]="!last"
                    [class.pt-2]="first"
                >
                    <span class="me-2" appIcon="telephone" color="car" size="5"></span>
                    <span>{{ phone ?? '-' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
