import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { HistoryActions } from '../actions/history.actions';
import { selectAll, selectHistory } from '../selectors/history.selector';
import { selectItineraries } from '../selectors/history.selector';
import { ItineraryEntityDiff } from '../models/itinirary-diff';
import { History } from '../models/history';

@Injectable({
    providedIn: 'root',
})
export class HistoryFacade {
    private store = inject(Store);

    readonly history$ = this.store.select(selectHistory);
    readonly itineraries$ = this.store.select(selectItineraries);
    readonly all$ = this.store.select(selectAll).pipe(filter((data): data is (History | ItineraryEntityDiff)[] => data !== null));

    getHistory(orderId: string, bookingId: string): void {
        this.store.dispatch(HistoryActions.getHistory({ orderId, bookingId }));
    }

    clear(): void {
        this.store.dispatch(HistoryActions.clear());
    }
}
