import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { HistoryEntity } from '@app/store/history';

@Component({
    selector: 'app-history-accordion',
    templateUrl: './history-accordion.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryAccordionComponent {
    @Input() entity!: HistoryEntity;
}
