import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { combineLatest, debounceTime, map, startWith } from 'rxjs';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, createUrlTreeFromSnapshot } from '@angular/router';
import { SidebarBaseComponent } from '@app/shell/sidebar/components/sidebar/sidebar.component';
import { History, HistoryFacade } from '@app/store/history';
import { ItineraryEntityDiff } from '@app/store/history/models/itinirary-diff';
import { Grow } from '@app/shared/animations';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss', '../../../../../shell/sidebar/styles/sidebar.scss'],
    animations: [Grow],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent extends SidebarBaseComponent implements OnInit, OnDestroy {
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private historyFacade = inject(HistoryFacade);
    private formBuilder = inject(NonNullableFormBuilder);

    readonly orderId = <string>this.activatedRoute.snapshot.firstChild?.paramMap.get('order');
    readonly bookingId = <string | undefined>this.activatedRoute.snapshot.firstChild?.paramMap.get('booking');
    readonly parcelId = <string | undefined>this.activatedRoute.snapshot.firstChild?.paramMap.get('parcel');

    readonly form?: FormGroup;
    readonly skipConfirm = true;

    readonly filterForm = this.formBuilder.group({
        insert: this.formBuilder.control(true),
        update: this.formBuilder.control(true),
        remove: this.formBuilder.control(true),
        planning: this.formBuilder.control(true),
    });

    readonly all$ = combineLatest([
        this.historyFacade.all$,
        this.filterForm.valueChanges.pipe(
            startWith(true),
            map(() => this.filterForm.getRawValue()),
        ),
    ]).pipe(
        debounceTime(100),
        map(([histories, filters]) => {
            const filtered = histories.filter((history) => {
                if (history instanceof ItineraryEntityDiff && filters.planning === true) {
                    return true;
                } else if (history instanceof History && history.type !== null) {
                    return filters[history.type];
                }
                return false;
            });
            return filtered;
        }),
    );

    ngOnInit(): void {
        const id = this.bookingId ?? this.parcelId ?? null;
        if (id !== null) {
            this.historyFacade.getHistory(this.orderId, id);
        }
    }

    override ngOnDestroy(): void {
        this.historyFacade.clear();
        super.ngOnDestroy();
    }

    closeClick() {
        this.goBack();
    }

    private goBack() {
        const tree = createUrlTreeFromSnapshot(this.activatedRoute.snapshot, []);
        const params = { ...tree.root.children['primary'].segments[0].parameters };
        delete params['history'];
        tree.root.children['primary'].segments[0].parameters = params;
        this.router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
    }

    isHistory(item: History | ItineraryEntityDiff): History | null {
        return item instanceof History ? item : null;
    }

    isItinerary(item: History | ItineraryEntityDiff): ItineraryEntityDiff | null {
        return item instanceof ItineraryEntityDiff ? item : null;
    }

    toggleFilter(event: MouseEvent | KeyboardEvent, filter: string) {
        event.preventDefault();
        const value = !this.filterForm.get(filter)?.getRawValue() || false;
        this.filterForm.get(filter)?.patchValue(value);
    }
}
