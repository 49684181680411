<div class="mb-8">
    <div *ngFor="let item of form.controls; index as i; let last = last" [class.mb-4]="!last">
        <app-payments-add-item-control
            [formGroup]="form.controls.at(i)!"
            (deleteItem)="deleteItemEvent(i)"
            [disableRideOption]="disableRideOption"
            [type]="type"
        />
    </div>
    <div class="pt-2 text-start">
        <a href="#" class="link text-primary fs-16" (click)="addItemClick($event)">Dodaj Usługę</a>
    </div>
</div>

<div class="d-flex align-items-center">
    <div class="ms-auto pt-8">
        <span class="fw-700 me-2 fs-20 text-uppercase">{{ 'PAYMENTS.FORM.SERVICES_PAYMENTS.sum' | translate }}</span>
        <span class="fw-700 fs-20 text-uppercase">{{ (priceTotal$ | async) || 0 }}</span>
    </div>
</div>
