import { createReducer, on } from '@ngrx/store';
import { HistoryActions } from '../actions/history.actions';

import { History } from '../models/history';
import { ItineraryEntityDiff } from '../models/itinirary-diff';

export const SERVICES_HISTORY_FEATURE_KEY = 'history';

export interface HistoryState {
    history: History[] | null;
    itineraries: ItineraryEntityDiff[] | null;
}

export const initialState: HistoryState = {
    history: null,
    itineraries: null,
};

export const historyReducer = createReducer(
    initialState,

    on(HistoryActions.getHistorySuccess, (state, action): HistoryState => {
        return {
            ...state,
            history: action.payload,
        };
    }),

    on(HistoryActions.getHistoryError, (state): HistoryState => {
        return {
            ...state,
            history: [],
        };
    }),

    on(HistoryActions.getHistoryPlanningSuccess, (state, action): HistoryState => {
        return {
            ...state,
            itineraries: action.payload,
        };
    }),

    on(HistoryActions.getHistoryPlanningError, (state): HistoryState => {
        return {
            ...state,
            itineraries: [],
        };
    }),

    on(HistoryActions.clear, (state): HistoryState => {
        return {
            ...state,
            ...initialState,
        };
    }),
);
