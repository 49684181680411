<form [formGroup]="control">
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="mb-5">
                <app-form-input formControlGroup="street" [block]="true" [label]="translationScope + 'FORM.street' | translate"></app-form-input>
            </div>
        </div>
        <div class="col-12 col-md-3">
            <div class="mb-5">
                <app-form-input formControlGroup="houseNumber" [block]="true" [label]="translationScope + 'FORM.house_number' | translate"></app-form-input>
            </div>
        </div>
        <div class="col-12 col-md-3">
            <div class="mb-5">
                <app-form-input formControlGroup="postalCode" [block]="true" [label]="translationScope + 'FORM.postal_code' | translate"></app-form-input>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div>
                <app-form-input formControlGroup="city" [block]="true" [label]="translationScope + 'FORM.city' | translate"> </app-form-input>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="mb-8">
                <app-form-dropdown
                    formControlGroup="country"
                    [options]="(countryList$ | async) || []"
                    [label]="translationScope + 'FORM.country' | translate"
                    [block]="true"
                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                ></app-form-dropdown>
            </div>
        </div>
    </div>
</form>
