import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, createUrlTreeFromSnapshot } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { OrderBookingPersonModel } from '@app/store/order';
import { FormService } from '../../../../services/form-edit.service';
import { PassengerUpdateFormGroup } from '../../../../models/form.value';

@Component({
    selector: 'app-passenger-section',
    templateUrl: './passenger-section.component.html',
    styleUrls: ['./passenger-section.component.scss'],
})
export class PassengerSectionComponent implements OnInit {
    @Input() booking!: OrderBookingPersonModel;
    form!: FormGroup<PassengerUpdateFormGroup>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formService: FormService,
    ) {}

    ngOnInit() {
        const index = this.formService.form.controls.passengers.value.findIndex((value) => value.id === this.booking.id);
        this.form = this.formService.form.controls.passengers.at(index);
    }

    editBooking(event: MouseEvent) {
        event.preventDefault();
        const tree = createUrlTreeFromSnapshot(this.activatedRoute.snapshot, []);
        const parameters = { ...tree.root.children['primary'].segments[0].parameters };
        parameters['booking-edit'] = this.booking.id;
        tree.root.children['primary'].segments[0].parameters = parameters;
        this.router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
    }

    passengerName() {
        return `${this.form.value.personalDetails?.firstname || ''} ${this.form.value.personalDetails?.surname || ''}`.trim();
    }
}
