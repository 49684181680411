<div [style.width.rem]="innerWidth" class="inner relative bg-lightest relative">
    <div #divBlock class="ps scroll">
        <div pFocusTrap>
            <form (ngSubmit)="onSubmit()" [formGroup]="form" appScrollToInvalidInput [ps]="ps">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h2 class="tw-mb-4 tw-text-[2rem]">{{ 'ORDER.booking' | translate }}</h2>
                    </div>
                    <div class="">
                        <button pButton (click)="openHistory($event)">{{ 'BOOKINGS.service_history' | translate }}</button>
                    </div>
                </div>

                <ng-container *ngIf="booking$ | async as booking">
                    <div *ngIf="booking.itineraries.items.length > 0" class="mb-12">
                        <app-box [border]="true">
                            <app-itinerary-details [itineraries]="booking.itineraries.items" />
                        </app-box>
                    </div>

                    <ng-container *ngIf="order$ | async as order">
                        <h3 class="tw-mb-3 tw-text-[1.5rem]">{{ 'ORDER.SECTION.customer_details' | translate }}</h3>
                        <div class="mb-12">
                            <app-box [border]="true">
                                <app-form-section [divider]="false" [title]="('ORDER.title' | translate) + order.number">
                                    <app-form-customer [isEditMode]="true" formGroupName="details" [translationScope]="'ORDER'" />
                                </app-form-section>
                            </app-box>
                        </div>
                    </ng-container>

                    <div class="mb-12" *ngIf="form.controls.parcels.at(0)">
                        <app-box [border]="true">
                            <app-parcel-form [formGroup]="form.controls.parcels.at(0)" />
                        </app-box>
                    </div>
                </ng-container>

                <ng-container *ngIf="order$ | async as order">
                    <h3 class="tw-mb-3 tw-text-[1.5rem]">Zdarzenia</h3>
                    <div class="mb-12">
                        <app-box [border]="true">
                            <app-form-didntgo-reason [formGroup]="form.controls.parcels.at(0)" />
                        </app-box>
                    </div>
                </ng-container>

                <div *ngIf="bookings$ | async as bookings">
                    <ng-container *ngIf="bookings.length > 0">
                        <h3 class="tw-mb-3 tw-text-[1.5rem]">{{ 'ORDER.SECTION.parcels' | translate }}</h3>
                        <ng-container *ngFor="let booking of bookings; trackBy: trackByBooking; let index = index">
                            <div class="mb-12">
                                <app-box [border]="true">
                                    <app-parcel-section [booking]="booking" />
                                </app-box>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <ng-container *ngIf="order$ | async as order">
                    <ng-container *ngIf="order.transactions.length > 0">
                        <h3 class="tw-mb-3 tw-text-[1.5rem]">{{ 'BOOKINGS.FORM.SECTIONS.services_payment' | translate }}</h3>
                        <app-parcel-transaction-edit />
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="order$ | async as order">
                    <app-form-invoice
                        formGroupName="invoiceDetails"
                        [isInvoiceMethod]="isInvoiceMethod$ | async"
                        [partner]="form.controls.details.controls.partner"
                    ></app-form-invoice>
                </ng-container>

                <div *ngIf="booking$ | async" class="d-flex justify-content-end">
                    <div class="me-4">
                        <p-button (click)="onCancelClick($event)" [label]="'BUTTONS.cancel' | translate"></p-button>
                    </div>
                    <p-button
                        [disabled]="(autosuggestLoading$ | async) === true ? true : false"
                        type="submit"
                        [label]="'ORDER.BUTTON.update_order' | translate"
                    />
                </div>
            </form>
        </div>
    </div>
</div>
