<ng-container *ngIf="order$ | async as order">
    <form [formGroup]="form">
        <div formArrayName="transactions">
            <div @Grow *ngFor="let transaction of form.controls.transactions.controls; index as index; last as last" [@.disabled]="animationDisabled$ | async">
                <div [class.pt-12]="index !== 0">
                    <app-parcel-transaction-set-edit
                        [formGroupName]="index"
                        [transactionIndex]="index"
                        (deleteTransactionEvent)="deleteTransactionEvent(index)"
                    />
                </div>

                <ng-container *ngFor="let item of transaction.controls.items.controls">
                    <div
                        @Grow
                        *ngIf="item.controls.status.getRawValue() === 'resigned' && item.controls.type.getRawValue() === serviceTypes.RIDE"
                        [@.disabled]="animationDisabled$ | async"
                    >
                        <app-form-resign-reason
                            *ngIf="item.controls.bookingId.getRawValue() !== null"
                            class="pt-8"
                            [formGroup]="parcelUpdateForm(item.controls.bookingId.getRawValue()!)!"
                        />
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</ng-container>

<div class="mb-12 pt-4">
    <a href="#" class="link tw-text-primary fs-14" (keyup)="createTransaction($event)" (click)="createTransaction($event)">Dodaj Koszyk</a>
</div>
