import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResignReasonsFacade } from '@app/store/resign-reasons';

const COUNTRY_PROPS = ['customer.address.country', 'country', 'route.from.country', 'route.to.country'];
const PAYMENT_PROPS = ['status', 'paymentsStatus', 'computedItemRidePaymentStatus'];
const ENUM_PROPS = ['rideStatus', 'planningType', 'customer.type', 'currency', 'size', 'method', 'provider', 'language', 'resultStatus', 'source'];
const PRICE_PROPS = ['amount', 'price', 'price.pln', 'price.eur'];

@Pipe({
    name: 'diffValue',
    standalone: true,
})
export class DiffValuePipe implements PipeTransform {
    private translateService = inject(TranslateService);
    private resignReasonsFacade = inject(ResignReasonsFacade);

    transform(value: string | number | null | boolean | null, type: string): string {
        if (type === 'currency' && typeof value === 'string') {
            return value;
        }

        if (type === 'didntGoReason' && typeof value === 'number') {
            return this.resignReasonsFacade.didntGoReasons().find((reason) => reason.value === value)?.name ?? 'UNKNOWN';
        }

        if (type === 'resignReason' && typeof value === 'number') {
            return this.resignReasonsFacade.resignReasons().find((reason) => reason.value === value)?.name ?? 'UNKNOWN';
        }

        // country fields
        if (COUNTRY_PROPS.includes(type) && typeof value === 'string') {
            return this.translateService.instant(`COUNTRY.${value}`);
        }

        // payment fields
        if (PAYMENT_PROPS.includes(type) && typeof value === 'string') {
            return this.translateService.instant(`DICTIONARIES.PAYMENT_STATUS_TYPES.${value}`);
        }

        // transletable enum fields
        if (ENUM_PROPS.includes(type) && (typeof value === 'string' || typeof value === 'number')) {
            if (type === 'method' || type === 'provider') {
                return this.translateService.instant(`payment.payment-type.${value}.label`);
            }
            return this.translateService.instant(`SERVICES.HISTORY.VALUE.${type}.${value}`);
        }

        // price
        if (PRICE_PROPS.includes(type) && (typeof value === 'string' || typeof value === 'number')) {
            if (typeof value === 'string') {
                return (parseInt(value, 10) / 100).toString();
            }
            return (value / 100).toString();
        }

        // all kind of values
        switch (typeof value) {
            case 'string':
                return value;
            case 'number':
                return value.toString();
            case 'boolean':
                return this.translateService.instant(`SERVICES.HISTORY.VALUE.${value === true ? 'yes' : 'no'}`);
            default:
                return '---';
        }
    }
}
