<div class="d-flex" [formGroup]="form">
    <div
        class="d-flex flex-grow-1 position-relative"
        [class.crossed]="form.getRawValue().status === 'resigned'"
        [class.resigned]="form.getRawValue().status === 'resigned'"
    >
        <div class="form-control me-4">
            <app-form-dropdown
                formControlGroup="bookingId"
                [options]="(bookingOptionsList$ | async) || []"
                [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                [label]="itemIndex === 0 ? 'Osoba' : ''"
                [block]="true"
                [showErrors]="false"
            ></app-form-dropdown>
        </div>
        <div class="form-control me-4">
            <app-form-dropdown
                formControlGroup="type"
                [options]="servicesTypesAvailableList"
                [label]="itemIndex === 0 ? 'Nazwa' : ''"
                [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                [block]="true"
                [showErrors]="false"
            ></app-form-dropdown>
        </div>
        <div class="flex-grow-1 me-4">
            <app-form-input formControlGroup="notes" [label]="itemIndex === 0 ? 'Uwagi' : ''" [block]="true"></app-form-input>
        </div>
        <div class="form-control">
            <app-form-input-number
                formControlGroup="price"
                [label]="itemIndex === 0 ? 'Kwota' : ''"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                [block]="true"
                [showErrors]="true"
            ></app-form-input-number>
        </div>
        <div class="d-flex align-items-center ms-8" [class.pt-8]="itemIndex === 0">
            <div class="text-none ms-7" *ngIf="form.getRawValue().status !== 'resigned'">
                <span
                    class="pointer"
                    appIcon="trash3"
                    color="danger"
                    size="6"
                    (click)="onDeleteClick($event)"
                    (keypress)="onDeleteClick($event)"
                    tabindex="1"
                ></span>
            </div>
            <ng-container *ngIf="form.getRawValue().status === 'resigned'">
                <div class="text-none ms-7">
                    <span
                        *ngIf="(transferResigned$ | async) === false || form.getRawValue().type === servicesTypes.RIDE; else placeholderButton"
                        class="pointer"
                        appIcon="plus-circle"
                        color="success"
                        size="6"
                        (click)="onRestoreClick($event)"
                        (keypress)="onRestoreClick($event)"
                        tabindex="1"
                    ></span>
                    <ng-template #placeholderButton>
                        <span appIcon="plus-circle" color="muted" size="6" tabindex="1"></span>
                    </ng-template>
                </div>
            </ng-container>
        </div>
    </div>
</div>
