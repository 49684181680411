<div [formGroup]="form">
    <div *ngIf="form.value.id !== null" class="mb-8">
        <div class="row">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    {{ 'PARCELS.unique_number' | translate }} <strong>{{ (form.getRawValue().id | bookingNumber | async) || '-' }}</strong>
                    <span class="border-start ms-6 ps-6">
                        {{ 'PARCELS.delivery_code' | translate }} <strong>{{ (form.getRawValue().id | deliveryCode | async) || '-' }}</strong>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <app-form-section
        [accordion]="true"
        [alert]="
            (form.controls.planningType.invalid || form.controls.departureDate.invalid) &&
            (form.controls.planningType.touched || form.controls.departureDate.touched)
        "
        [open]="true"
        [divider]="false"
        [title]="'PARCELS.FORM.SECTIONS.planning_type' | translate"
    >
        <div class="pb-5">
            <div class="row">
                <div class="col-md-3 col-6">
                    <app-form-dropdown
                        formControlGroup="planningType"
                        [options]="(planningTypes$ | async) ?? []"
                        [block]="true"
                        [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                    />
                </div>
                <div class="col-md-3 col-6">
                    <app-form-datepicker formControlGroup="departureDate" [block]="true" placeholder="dd.mm.yyyy" />
                    <ng-container *ngIf="form.value.id !== null">
                        <ng-container *ngIf="form.getRawValue().id | bookingDateHistory | async as dateHistory">
                            <div *ngIf="dateHistory.length > 0" class="pt-2 ps-4">
                                <app-services-date-history [translationScope]="'PARCELS.'" [dateHistory]="dateHistory"></app-services-date-history>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div *ngIf="form.value.id !== null" class="col-md-6 col-12 text-end">
                    <div class="pt-3">
                        <div class="me-5 d-inline-block">
                            <app-form-switch formControlGroup="complaint" label="{{ 'PARCELS.FORM.complaint' | translate }}" direction="column" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-form-section>

    <app-form-section
        [accordion]="true"
        [alert]="form.controls.recipient.invalid && form.controls.recipient.touched"
        [open]="true"
        [title]="'PARCELS.FORM.SECTIONS.recipient_details' | translate"
    >
        <app-form-personal-details formControlGroup="recipient" [translationScope]="'PARCELS.'"></app-form-personal-details>
    </app-form-section>

    <app-form-section
        [accordion]="true"
        [alert]="(form.controls.from.invalid || form.controls.to.invalid) && (form.controls.from.touched || form.controls.to.touched)"
        [open]="true"
        [title]="'PARCELS.FORM.SECTIONS.departure-and-destination' | translate"
    >
        <div class="d-flex">
            <div [style.width.%]="46">
                <app-form-autosuggest formControlGroup="from">
                    <app-address-preview
                        *ngIf="form.controls.id.getRawValue() !== null"
                        preview
                        [bookingId]="form.controls.id.getRawValue()"
                        direction="from"
                    />
                </app-form-autosuggest>
            </div>

            <div [style.width.%]="8" class="align-self-center text-center">
                <button
                    [disabled]="(autosuggestLoading$ | async) === true ? true : null"
                    class="btn btn-round px-5 btn-success"
                    (click)="onSwitchAddressClick($event)"
                >
                    <span appIcon="arrow-left-right" color="white" size="6"></span>
                </button>
            </div>

            <div [style.width.%]="46">
                <app-form-autosuggest formControlGroup="to">
                    <app-address-preview *ngIf="form.controls.id.getRawValue() !== null" preview [bookingId]="form.controls.id.getRawValue()" direction="to" />
                </app-form-autosuggest>
            </div>
        </div>
    </app-form-section>

    <app-form-section
        [accordion]="true"
        [alert]="
            (form.controls.size.invalid || form.controls.weight.invalid || form.controls.value.invalid) &&
            (form.controls.size.touched || form.controls.weight.touched || form.controls.value.touched)
        "
        [open]="true"
        [title]="'PARCELS.FORM.SECTIONS.parcel_details' | translate"
    >
        <div class="row">
            <div class="col-md-4">
                <app-form-dropdown
                    formControlGroup="size"
                    [options]="parcelSizes"
                    [block]="true"
                    [label]="'PARCELS.FORM.size' | translate"
                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                ></app-form-dropdown>
            </div>
            <div class="col-md-4">
                <app-form-input-number
                    formControlGroup="weight"
                    [label]="'PARCELS.FORM.weight' | translate"
                    [placeholder]="'FORMS.PLACEHOLDERS.weight' | translate"
                    [block]="true"
                    [min]="0"
                    [max]="100"
                ></app-form-input-number>
            </div>
            <div class="col-md-4">
                <app-form-input-number formControlGroup="value" [label]="'PARCELS.FORM.value' | translate" [block]="true" [min]="0"></app-form-input-number>
            </div>
        </div>
    </app-form-section>

    <app-form-section [accordion]="true" *ngIf="formIsCreate === true" [title]="'PARCELS.FORM.SECTIONS.services_payment' | translate">
        <app-payments-add-control formGroupName="items" [type]="bookingTypes.PARCEL" [disableRideOption]="true"></app-payments-add-control>
    </app-form-section>

    <app-form-section
        [accordion]="true"
        [alert]="
            (form.controls.contentDescription.invalid ||
                form.controls.driverNotes.invalid ||
                form.controls.internalNotes.invalid ||
                form.controls.notes.invalid) &&
            (form.controls.contentDescription.touched ||
                form.controls.internalNotes.touched ||
                form.controls.driverNotes.touched ||
                form.controls.notes.touched)
        "
        [open]="notesOpen"
        [title]="'BOOKINGS.FORM.SECTIONS.misc' | translate"
    >
        <div class="mb-4">
            <app-form-textarea formControlGroup="contentDescription" [label]="'PARCELS.FORM.content_description' | translate" [block]="true" />
        </div>
        <div class="mb-4">
            <app-form-textarea formControlGroup="notes" [label]="'PARCELS.FORM.notes' | translate" [block]="true" />
        </div>
        <div class="mb-4">
            <app-form-textarea formControlGroup="driverNotes" [label]="'PARCELS.FORM.driver_notes' | translate" [block]="true" />
        </div>
        <div class="mb-4">
            <app-form-textarea formControlGroup="internalNotes" [label]="'PARCELS.FORM.internal_notes' | translate" [block]="true" />
        </div>
    </app-form-section>

    <app-form-section *ngIf="formIsCreate === false" [accordion]="true" [open]="true" [title]="'BOOKINGS.FORM.SECTIONS.comments' | translate">
        <div class="border rounded p-4">
            <div class="row">
                <div class="col-6">
                    <div class="mb-2">
                        {{ 'PARCELS.pickup_date' | translate }}
                        <span class="fw-700">{{ (form.getRawValue().id | pickupDate | async | date: 'dd.MM.yyyy') || '-' }}</span>
                    </div>
                    <div>
                        {{ 'PARCELS.pickup_notes' | translate }} <span class="fw-700">{{ (form.getRawValue().id | pickupNotes | async) || '-' }}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-2">
                        {{ 'PARCELS.delivery_date' | translate }}
                        <span class="fw-700">{{ (form.getRawValue().id | deliveryDate | async | date: 'dd.MM.yyyy') || '-' }}</span>
                    </div>
                    <div>
                        {{ 'PARCELS.delivery_notes' | translate }} <span class="fw-700">{{ (form.getRawValue().id | deliveryNotes | async) || '-' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </app-form-section>
</div>
