import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { History } from '@app/store/history';

@Component({
    selector: 'app-history-entry',
    templateUrl: './history-entry.component.html',
    styleUrls: ['./history-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryEntryComponent {
    @Input() history!: History;
    @Input() last!: boolean;

    @HostBinding('class.last') get class() {
        return this.last;
    }

    get severity() {
        switch (this.history.type) {
            case 'insert':
                return 'primary';
            case 'update':
                return 'info';
            case 'remove':
                return 'danger';
            default:
                return 'dark';
        }
    }

    get icon() {
        switch (this.history.type) {
            case 'insert':
                return 'plus-lg';
            case 'update':
                return 'arrow-repeat';
            case 'remove':
                return 'x-lg';
            default:
                return 'question-lg';
        }
    }
}
