import { trigger, transition, animate, style, group } from '@angular/animations';

export const OpenCloseSlide = trigger('openCloseSlide', [
    transition(':enter', [
        group([
            style({ transform: 'translateY(2rem)', opacity: 0 }),
            animate('0.25s cubic-bezier(0.5, 0, 0.1, 1)', style({ transform: 'translateY(0)', opacity: 1 })),
        ]),
    ]),
    transition(':leave', [group([animate('0.25s cubic-bezier(0.5, 0, 0.1, 1)', style({ transform: 'translateY(2rem)', opacity: 0 }))])]),
]);

export const ShowHide = trigger('showHide', [
    transition(':enter', [style({ opacity: 0 }), animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({ opacity: 1 }))]),
    transition(':leave', [animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({ opacity: 0 }))]),
]);
