<form [formGroup]="control">
    <div class="row">
        <div class="col-12" [ngClass]="vertical === false ? 'col-md-6' : 'col-md-12'">
            <div class="mb-5">
                <app-form-input formControlGroup="firstname" [block]="true" [label]="translationScope + 'FORM.name' | translate"></app-form-input>
            </div>
        </div>
        <div class="col-12" [ngClass]="vertical === false ? 'col-md-6' : 'col-md-12'">
            <div class="mb-5">
                <app-form-input formControlGroup="surname" [block]="true" [label]="translationScope + 'FORM.surname' | translate"></app-form-input>
            </div>
        </div>
        <div class="col-12" [ngClass]="vertical === false ? 'col-md-6' : 'col-md-12'">
            <div>
                <app-form-input formControlGroup="email" [block]="true" [label]="translationScope + 'FORM.email' | translate"></app-form-input>
            </div>
        </div>
        <div class="col-12" [ngClass]="vertical === false ? 'col-md-6' : 'col-md-12'">
            <div>
                <app-form-input
                    formControlGroup="phone"
                    [placeholder]="'+48 000 000 000'"
                    [block]="true"
                    [label]="translationScope + 'FORM.phone' | translate"
                ></app-form-input>
            </div>
        </div>
    </div>
    <a *ngIf="customerPersonaDetails" href="#" class="link d-inline-block text-primary fs-16 pt-2" (click)="copyOrderToFirstBooking($event)"
        >Kopiuj dane z rezerwacji</a
    >
</form>
