import { AfterViewInit, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { BehaviorSubject, Subscription, map, startWith, take, tap, timer } from 'rxjs';
import { ITEM_STATUS, OrderFacade, SERVICE_TYPES } from '@app/store/order';
import { FormService } from '../../../../services/form-edit.service';
import { Grow } from '@app/shared/animations';

@Component({
    selector: 'app-parcel-transaction-edit',
    templateUrl: './parcel-transaction-edit.component.html',
    styleUrls: ['./parcel-transaction-edit.component.scss'],
    animations: [Grow],
})
export class ParcelTransactionEditComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly orderFacade = inject(OrderFacade);
    private readonly formService = inject(FormService);

    readonly serviceTypes = SERVICE_TYPES;

    order$ = this.orderFacade.order$;
    closed$ = this.order$.pipe(map((order) => order.transactions.some((transaction) => transaction.closed === false)));
    form = this.formService.form;

    private subscriptions$ = new Subscription();

    animationDisabled$ = new BehaviorSubject(true);

    ngOnInit() {
        this.subscriptions$.add(
            // toggle items on ride status change
            this.form.controls.transactions.valueChanges
                .pipe(
                    startWith(this.form.controls.transactions.getRawValue()),
                    map(() => this.form.controls.transactions.getRawValue()),
                    tap((transactions) => {
                        const bookingIds = transactions
                            .map((transaction) => transaction.items)
                            .flat()
                            .filter((item) => item.type === SERVICE_TYPES.RIDE && item.status === ITEM_STATUS.RESIGNED)
                            .map((item) => item.bookingId);

                        this.form.controls.transactions.controls.forEach((transaction) => {
                            transaction.controls.items.controls.forEach((item, index) => {
                                if (bookingIds.includes(item.getRawValue().bookingId)) {
                                    if (item.getRawValue().id === null) {
                                        transaction.controls.items.removeAt(index, { emitEvent: false });
                                    } else {
                                        item.controls.status.patchValue(ITEM_STATUS.RESIGNED, { emitEvent: false });
                                        item.disable({ emitEvent: false });
                                    }
                                }
                            });
                        });
                    }),
                )
                .subscribe(),
        );
    }

    ngAfterViewInit() {
        this.subscriptions$.add(
            timer(0)
                .pipe(
                    tap(() => this.animationDisabled$.next(false)),
                    take(1),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    createTransaction(event: MouseEvent | KeyboardEvent) {
        event.preventDefault();
        this.formService.addTransaction();
    }

    deleteTransactionEvent(index: number) {
        this.form.controls.transactions.removeAt(index);
    }

    readonly parcelUpdateForm = (bookingId: string) => this.form.controls.parcels.controls.find((parcel) => parcel.controls.id.getRawValue() === bookingId);
}
