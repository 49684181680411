import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { OpenClose } from '@app/shared/animations';
import { ItineraryDiff } from '@app/store/history/models/itinirary-diff';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-itinirary-tour',
    templateUrl: './itinirary-tour.component.html',
    styleUrls: ['./itinirary-tour.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [OpenClose],
})
export class ItiniraryTourComponent {
    @Input() itinerary!: ItineraryDiff;
    @Input() first!: boolean;
    @Input() last!: boolean;

    hover$ = new BehaviorSubject(false);

    severity(status: string) {
        switch (status) {
            case 'added':
                return 'success';
            case 'updated':
                return 'secondary';
            case 'removed':
                return 'danger';
            case 'untouched':
            default:
                return 'default';
        }
    }

    mouseEnter() {
        this.hover$.next(true);
    }

    mouseLeave() {
        this.hover$.next(false);
    }
}
