import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Subscription, combineLatest, distinctUntilChanged, map, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BOOKING_TYPES } from '@app/store/planning/enums';
import { ParcelCreateFormGroup, ParcelUpdateFormGroup } from '../../../models/form.value';
import { parcelSizes } from '@app/shared/services/parcel-sizes';
import { AutosuggestClosestFacade, AutosuggestFacade } from '@app/store/heremaps';
import { DictionariesFacade } from '@app/dictionaries/store/facades/dictionaries.facade';
import { SelectOptionsFacade } from '@app/store/select-options';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';
import { PopupService } from '@app/shared/popup';

@Component({
    selector: 'app-parcel-form',
    templateUrl: './parcel-form.component.html',
    styleUrls: ['./parcel-form.component.scss'],
})
export class ParcelFormComponent implements OnInit, OnDestroy {
    private readonly translateService = inject(TranslateService);
    private readonly controlContainer = inject(ControlContainer);
    private readonly autosuggestFacade = inject(AutosuggestFacade);
    private readonly autosuggestClosestFacade = inject(AutosuggestClosestFacade);
    private readonly dictionariesFacade = inject(DictionariesFacade);
    private readonly selectOptionsFacade = inject(SelectOptionsFacade);
    private readonly popupService = inject(PopupService);

    autosuggestLoading$ = combineLatest([this.autosuggestFacade.autosuggestLoading$, this.autosuggestClosestFacade.autosuggestLoading$]).pipe(
        map((loaders) => loaders.includes(true)),
    );

    @Input()
    formGroupName!: string;

    parcelSizes = parcelSizes.map((size) => ({ label: this.translateService.instant(size.label), value: size.value }));
    planningTypes$ = this.dictionariesFacade.planningTypes$;
    bookingTypes = BOOKING_TYPES;
    partners$ = this.selectOptionsFacade.partners$;
    notesOpen = false;

    form!: FormGroup<ParcelCreateFormGroup | ParcelUpdateFormGroup>;
    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.form = <FormGroup<ParcelCreateFormGroup | ParcelUpdateFormGroup>>this.controlContainer.control;
        this.notesOpen =
            Boolean(this.form.value.contentDescription) ||
            Boolean(this.form.value.driverNotes) ||
            Boolean(this.form.value.internalNotes) ||
            Boolean(this.form.value.notes);

        this.subscriptions$.add(
            this.form.controls.departureDate.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    tap((value) => {
                        if (value !== null && value < new Date()) {
                            this.popupService.open(AlertPopupComponent);
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onSwitchAddressClick(event: Event) {
        event.preventDefault();

        // without event
        this.form.patchValue(
            {
                from: this.form.controls.to.getRawValue(),
                to: this.form.controls.from.getRawValue(),
            },
            { emitEvent: false },
        );

        // with event
        this.form.controls.to.patchValue({
            accuracy: this.form.controls.to.controls.accuracy.getRawValue(),
            geoPosition: this.form.controls.to.controls.geoPosition.getRawValue(),
            countryRestricted: this.form.controls.to.controls.countryRestricted.getRawValue(),
        });

        this.form.controls.from.patchValue({
            accuracy: this.form.controls.from.controls.accuracy.getRawValue(),
            geoPosition: this.form.controls.from.controls.geoPosition.getRawValue(),
            countryRestricted: this.form.controls.from.controls.countryRestricted.getRawValue(),
        });
    }

    get index() {
        return parseInt(this.formGroupName) + 1;
    }

    get formIsCreate(): boolean {
        return this.form.getRawValue().id === null;
    }

    get formCreate(): FormGroup<ParcelCreateFormGroup> {
        return this.form as FormGroup<ParcelCreateFormGroup>;
    }
}
