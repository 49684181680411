import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ItineraryEntityDiff } from '@app/store/history/models/itinirary-diff';

@Component({
    selector: 'app-itinirary-loop',
    templateUrl: './itinirary-loop.component.html',
    styleUrls: ['./itinirary-loop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItiniraryLoopComponent {
    @Input() entity!: ItineraryEntityDiff;

    severity(status: string) {
        switch (status) {
            case 'added':
                return 'success';
            case 'updated':
                return 'secondary';
            case 'removed':
                return 'danger';
            case 'untouched':
            default:
                return 'default';
        }
    }
}
