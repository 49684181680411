import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { OpenClose } from '@app/shared/animations';
import { PopupBaseComponent } from '@app/shared/popup';

@Component({
    selector: 'app-alert-popup',
    templateUrl: './alert-popup.component.html',
    styleUrls: ['./alert-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [OpenClose],
})
export class AlertPopupComponent extends PopupBaseComponent {
    @ViewChild('save') save!: ElementRef<HTMLElement>;

    constructor(public override renderer2: Renderer2) {
        super(renderer2);
    }

    onCloseClick(): void {
        this.close('confirm');
    }

    @HostListener('document:keydown.escape', ['$event'])
    onCancelEvent() {
        this.close('cancel');
    }
}
