import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { OrderPersonalDetailsValue } from '@app/store/order';
import { PersonalDetailsFormGroup } from '../../models/personal-details.form.value';

@Component({
    selector: 'app-form-personal-details',
    templateUrl: './form-personal-details.component.html',
})
export class FormPersonalDetailsComponent implements OnInit {
    @Input() translationScope!: string;
    @Input() formControlGroup!: string;
    @Input() vertical: boolean = false;
    @Input()
    customerPersonaDetails?: OrderPersonalDetailsValue;

    constructor(private controlContainer: ControlContainer) {}

    control!: FormGroup<PersonalDetailsFormGroup>;

    ngOnInit(): void {
        this.control = <FormGroup<PersonalDetailsFormGroup>>(<FormGroup<PersonalDetailsFormGroup>>this.controlContainer.control).get(this.formControlGroup);
    }

    copyOrderToFirstBooking(event: Event) {
        event.preventDefault();
        if (this.customerPersonaDetails) {
            this.control.patchValue(this.customerPersonaDetails);
        }
    }
}
