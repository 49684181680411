import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { History } from '../models/history';
import { ItineraryEntityDiff } from '../models/itinirary-diff';

export const HistoryActions = createActionGroup({
    source: 'History',
    events: {
        'Get History': props<{ orderId: string; bookingId: string }>(),
        'Get History Success': props<{ payload: History[] }>(),
        'Get History Error': emptyProps(),
        'Get History Planning Success': props<{ payload: ItineraryEntityDiff[] }>(),
        'Get History Planning Error': emptyProps(),
        Clear: emptyProps(),
    },
});
