import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, OnDestroy, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router, createUrlTreeFromSnapshot } from '@angular/router';
import { PerfectScrollbarService } from '@app/shared/services';
import { OpenCloseSlide } from '@app/shell/sidebar/animations/sidebar.animation';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription, fromEvent, tap } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    template: '',
    styleUrls: ['./sidebar.component.scss'],
    animations: [OpenCloseSlide],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarBaseComponent implements OnDestroy, AfterViewInit {
    private _elementRef = inject(ElementRef);
    private _activatedRoute = inject(ActivatedRoute);
    private _router = inject(Router);
    private _perfectScrollbarService = inject(PerfectScrollbarService);

    @ViewChild('divBlock') divBlock!: ElementRef<HTMLDivElement>;
    ps?: PerfectScrollbar;
    slideIndex?: number;

    @HostBinding('@openCloseSlide') get(): void {}

    @HostListener('dblclick', ['$event.target']) onClick(target: HTMLElement) {
        this.closeByTagname(target.tagName);
    }

    readonly subscriptions$ = new Subscription();

    ngAfterViewInit(): void {
        this.ps = new PerfectScrollbar(this._elementRef.nativeElement, { wheelPropagation: false });
        this.subscriptions$.add(
            fromEvent(this.ps.element, 'ps-scroll-y')
                .pipe(tap(() => this._perfectScrollbarService.scrollStart()))
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
        this.ps?.destroy();
        delete this.ps;
    }

    setSlideIndex(index: number) {
        this.slideIndex = index;
    }

    get innerWidth() {
        return 84;
    }

    private closeByTagname(tagName: string) {
        switch (tagName) {
            case 'APP-VIEW-BOOKING': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['order'];
                delete params['booking'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-EDIT-BOOKING': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['booking-edit'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-NEW-BOOKING': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['booking-new'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-CLONE-BOOKING': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['booking-clone'];
                delete params['order'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-VIEW-PARCEL': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['order'];
                delete params['parcel'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-EDIT-PARCEL': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['parcel-edit'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-NEW-PARCEL': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['parcel-new'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-HISTORY': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['history'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
            case 'APP-CLONE-PARCEL': {
                const tree = createUrlTreeFromSnapshot(this._activatedRoute.snapshot, []);
                const params = { ...tree.root.children['primary'].segments[0].parameters };
                delete params['parcel-clone'];
                delete params['order'];
                tree.root.children['primary'].segments[0].parameters = params;
                this._router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
                break;
            }
        }
    }
}
