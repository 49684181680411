import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { FormValidators } from '@app/shared/forms';
import { ITEM_STATUS, PAYMENT_TYPES } from '@app/store/order';
import { CURRENCY_TYPES, SERVICE_TYPES } from '@app/store/payment/enums';
import { BOOKING_TYPES } from '@app/store/planning/enums';
import { TranslateService } from '@ngx-translate/core';
import { ItemFormGroup } from '@app/services/shared/models/form.value';

@Component({
    selector: 'app-payments-add-control',
    templateUrl: './payments-add-control.component.html',
    styleUrls: ['payments-add-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentsAddControlComponent implements OnInit, OnDestroy {
    @Input() type!: BOOKING_TYPES;
    @Input() disableRideOption = false;

    form!: FormArray<FormGroup<ItemFormGroup>>;
    currencyTypes = Object.values(CURRENCY_TYPES).map((type) => ({ label: type, value: type }));
    paymentTypes = Object.values(PAYMENT_TYPES).map((type) => ({
        label: this.translateService.instant(`payment.payment-type.${type.toString()}.label`),
        value: type,
    }));

    priceTotal$!: Observable<string>;

    private subscriptions$ = new Subscription();

    constructor(
        private readonly formBuilder: NonNullableFormBuilder,
        private readonly controlContainer: ControlContainer,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.form = <FormArray<FormGroup<ItemFormGroup>>>this.controlContainer.control;

        this.priceTotal$ = this.form.valueChanges.pipe(
            startWith(this.form.getRawValue()),
            map(() => this.form.getRawValue()),
            map((items) =>
                items.reduce((sum: number, item) => {
                    if (item.type === SERVICE_TYPES.DISCOUNT) {
                        return sum - (item.price || 0);
                    }
                    return sum + (item.price || 0);
                }, 0),
            ),
            map((totalPrice) =>
                totalPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    addItemClick(event: MouseEvent) {
        event.preventDefault();

        this.form.push(
            this.formBuilder.group({
                id: this.formBuilder.control<string | null>(null),
                notes: this.formBuilder.control<string | null>(null),
                price: this.formBuilder.control<number>(0, { validators: [FormValidators.Required] }),
                type: this.formBuilder.control<SERVICE_TYPES | null>(null, { validators: [FormValidators.Required] }),
                name: this.formBuilder.control<string | null>(null),
                bookingId: this.formBuilder.control<string | null>(null),
                status: this.formBuilder.control<ITEM_STATUS | null>(null),
            }),
        );
    }

    deleteItemEvent(index: number) {
        this.form.removeAt(index);
    }
}
