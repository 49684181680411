import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, of } from 'rxjs';
import { HistoryActions } from '../actions/history.actions';
import { HttpService } from '../services/http.service';
import { GuiFacade } from '@app/gui';

@Injectable()
export class HistoryEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);
    private guiFacade = inject(GuiFacade);

    getHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HistoryActions.getHistory),
            mergeMap((action) => {
                this.guiFacade.showLoader('get-history');
                return this.httpService.getHistory(action.orderId).pipe(
                    map((response) => HistoryActions.getHistorySuccess({ payload: response })),
                    catchError(() => of(HistoryActions.getHistoryError())),
                    finalize(() => this.guiFacade.hideLoader('get-history')),
                );
            }),
        );
    });

    getHistoryPlanning$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HistoryActions.getHistory),
            mergeMap((action) => {
                this.guiFacade.showLoader('get-history-planning');
                return this.httpService.getHistoryPlanning(action.bookingId).pipe(
                    map((response) => HistoryActions.getHistoryPlanningSuccess({ payload: response })),
                    catchError(() => of(HistoryActions.getHistoryPlanningError())),
                    finalize(() => this.guiFacade.hideLoader('get-history-planning')),
                );
            }),
        );
    });
}
