import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest, map, startWith } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BOOKING_TYPES } from '@app/store/planning/enums';
import { ITEM_STATUS, OrderFacade, PAYMENT_PARCEL_SERVICE_TYPES, PAYMENT_PASSENGER_SERVICE_TYPES, PaymentItemFormValue, SERVICE_TYPES } from '@app/store/order';
import { FormService } from '../../../../../services/form-edit.service';
import { ItemFormGroup } from '../../../../../../../shared/models/form.value';

@Component({
    selector: 'app-transaction-edit-set-control',
    templateUrl: './transaction-edit-set-control.component.html',
    styleUrls: ['./transaction-edit-set-control.component.scss'],
})
export class TransactionEditSetControlComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);
    private readonly translateService = inject(TranslateService);
    private readonly orderFacade = inject(OrderFacade);
    private readonly formService = inject(FormService);

    @Input() formGroupName!: number;
    @Input() closed!: boolean;
    @Input() transactionIndex!: number;
    @Output() deleteItemEvent = new EventEmitter<number>();
    @Output() createItemEvent = new EventEmitter<number>();
    @Output() updateItemEvent = new EventEmitter<number>();
    @Output() sendPassengerEvent = new EventEmitter<string>();

    resignedPassengerId = new BehaviorSubject<string>('');
    servicesTypes = SERVICE_TYPES;
    servicesTypesAvailableList!: { label: string; value: string }[];
    bookingOptionsList$!: Observable<{ label: string; value: string }[]>;
    transferResigned$!: Observable<boolean>;

    get itemIndex() {
        return this.formGroupName;
    }

    form!: FormGroup<ItemFormGroup>;
    formValueCopy?: PaymentItemFormValue;

    ngOnInit(): void {
        this.form = <FormGroup<ItemFormGroup>>this.controlContainer.control;

        // Available services
        this.servicesTypesAvailableList = Object.values(1 === BOOKING_TYPES.PASSENGER ? PAYMENT_PASSENGER_SERVICE_TYPES : PAYMENT_PARCEL_SERVICE_TYPES)
            .filter((v) => typeof v === 'number')
            .filter((v) => {
                return v === SERVICE_TYPES.RIDE && this.form.getRawValue().type !== SERVICE_TYPES.RIDE ? false : true;
            })
            .map((type) => ({ label: this.translateService.instant(`payment.service-type.${type.toString()}.label`), value: type }));

        // block items if ride is resigned
        this.transferResigned$ = this.formService.form.controls.transactions.valueChanges.pipe(
            startWith(this.formService.form.controls.transactions.getRawValue()),
            map(() => this.formService.form.controls.transactions.getRawValue()),
            map((transactions) => {
                const itemRideResigned = transactions
                    .map((transaction) => transaction.items)
                    .flat()
                    .find(
                        (item) =>
                            item.bookingId === this.form.getRawValue().bookingId && item.status === ITEM_STATUS.RESIGNED && item.type === SERVICE_TYPES.RIDE,
                    );
                return itemRideResigned ? true : false;
            }),
        );

        this.bookingOptionsList$ = combineLatest([
            this.orderFacade.order$,
            this.formService.form.controls.transactions.valueChanges.pipe(
                startWith(this.formService.form.controls.transactions.getRawValue()),
                map(() => this.formService.form.controls.transactions.getRawValue()),
            ),
        ]).pipe(
            map(([order, transactions]) => {
                const resignedBookingIds = transactions
                    .map((transaction) => transaction.items)
                    .flat()
                    .filter((item) => item.type === SERVICE_TYPES.RIDE && item.status === ITEM_STATUS.RESIGNED)
                    .map((item) => item.bookingId);

                const bookings = [...order.bookings].filter((booking) => {
                    if (resignedBookingIds.includes(booking.id) && this.form.getRawValue().bookingId !== booking.id) {
                        return false;
                    }
                    return true;
                });

                return bookings.map((booking) => {
                    if ('passenger' in booking) {
                        return {
                            label: `${booking.passenger.firstName!} ${booking.passenger.lastName!}`,
                            value: booking.id,
                        };
                    }

                    return {
                        label: `${booking.parcel.recipient?.firstName} ${booking.parcel.recipient?.lastName}`,
                        value: booking.id,
                    };
                });
            }),
        );
    }

    onDeleteClick(event: MouseEvent | KeyboardEvent) {
        event.preventDefault();
        if (this.form.getRawValue().id === null) {
            this.deleteItemEvent.next(this.itemIndex);
        } else {
            this.form.controls.status.patchValue(ITEM_STATUS.RESIGNED);
            this.form.disable();
        }
    }

    onRestoreClick(event: MouseEvent | KeyboardEvent) {
        event.preventDefault();
        this.form.controls.status.patchValue(null);
        if (this.closed === false) {
            this.form.controls.notes.enable();
            this.form.controls.price.enable();
        }
    }
}
