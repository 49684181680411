import { HistoryEntityDto, ValueDto } from './history.dto';

const SKIP_PROPS = [
    'duplicates',
    'packageAmount',
    'route.to.accuracy',
    'route.from.accuracy',
    'customer.phoneSearch',
    'phoneSearch',
    'wasPlannedBeforeResign',
    'accuracy',
    'order',
    'time',
];

export class History {
    date: Date;
    user: HistoryUser;
    entities: HistoryEntity[];
    type: 'update' | 'insert' | 'remove' | null;
    constructor(entities: HistoryEntityDto[]) {
        this.date = new Date(new Date(entities[0].date).getTime() + new Date().getTimezoneOffset() * 60000);
        this.entities = entities.map((entity) => new HistoryEntity(entity)).filter((entity) => entity.differences.length !== 0);
        this.user = new HistoryUser(entities[0]);
        this.type = entities[0].type ?? null;
    }
}

export class HistoryUser {
    id: string | null;
    name: string | null;
    ipAddress: string | null;
    constructor(entity: HistoryEntityDto) {
        this.id = entity.actionUserId ?? null;
        this.name = entity.actionUsername ?? null;
        this.ipAddress = entity.actionIp ?? null;
    }
}

export class HistoryEntity {
    id: string;
    type: string;
    class: string;
    transaction: string;
    differences: Differences[];

    constructor(data: HistoryEntityDto) {
        this.id = data.entityId;
        this.type = data.entityType;
        this.class = data.entityClass;
        this.transaction = data.transaction;
        this.differences = Object.entries(data.differences)
            .filter(([key]) => SKIP_PROPS.includes(key) === false)
            .map(([key, value]) => new Differences(key, value));
    }
}

class Differences {
    prop: string;
    old: Value;
    new: Value;
    constructor(key: string, value: { new: ValueDto; old: ValueDto }) {
        this.prop = key;
        this.new =
            typeof value.new !== 'string' && typeof value.new !== 'number' && typeof value.new !== 'boolean' && value.new !== null ? value.new.id : value.new;
        this.old =
            typeof value.old !== 'string' && typeof value.old !== 'number' && typeof value.old !== 'boolean' && value.old !== null ? value.old.id : value.old;
    }
}

export type Value = string | number | null | boolean;
