<span class="icon-action border-{{ severity }}">
    <span [appIcon]="icon" [color]="severity" size="6"></span>
</span>

<p-panel styleClass="tw-mb-6" header="{{ history.date | date: 'dd.MM.yyy HH:mm:ss' }}">
    <p class="tw-mb-4">{{ 'SERVICES.HISTORY.author' | translate }}{{ history.user.id !== 'command' ? history.user.name : 'Synchronizacja' }}</p>
    <div *ngFor="let entity of history.entities" class="mb-1">
        <app-history-accordion [entity]="entity" />
    </div>
</p-panel>
