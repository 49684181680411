import { Component, Input } from '@angular/core';
import { OrderPersonalDetailsValue } from '@app/store/order';

@Component({
    selector: 'app-booking-personal-info',
    templateUrl: './booking-personal-info.component.html',
    styleUrls: ['./booking-personal-info.component.scss'],
})
export class BookingPersonalInfoComponent {
    @Input()
    value!: OrderPersonalDetailsValue;

    get name() {
        return [this.value.firstname?.trim(), this.value.surname?.trim()].join(' ');
    }
}
