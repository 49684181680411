import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OpenClose } from '@app/shared/animations';
import { Itinerary } from '@app/store/models';

@Component({
    selector: 'app-itinerary-tour',
    templateUrl: './itinerary-tour.component.html',
    styleUrls: ['./itinerary-tour.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [OpenClose],
})
export class ItineraryTourComponent {
    @Input() itinerary!: Itinerary;

    hover$ = new BehaviorSubject(false);

    mouseEnter() {
        this.hover$.next(true);
    }

    mouseLeave() {
        this.hover$.next(false);
    }
}
