import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HISTORY_FEATURE_KEY } from './keys';
import { historyReducer } from './reducers/history.reducer';
import { HistoryEffects } from './effects/history.effects';

@NgModule({
    imports: [StoreModule.forFeature(HISTORY_FEATURE_KEY, historyReducer), EffectsModule.forFeature([HistoryEffects])],
})
export class HistoryStoreModule {}
