import { Component, Input, OnInit, inject } from '@angular/core';
import { OrderFacade } from '@app/store/order';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-address-preview',
    styleUrls: ['./preview.component.scss'],
    templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnInit {
    @Input({ required: true }) bookingId!: string | null;
    @Input({ required: true }) direction!: 'from' | 'to';

    private orderFacade = inject(OrderFacade);
    address$!: Observable<unknown | null>;

    ngOnInit() {
        this.address$ = this.orderFacade.order$.pipe(
            map((order) => {
                const booking = order.bookings.find((booking) => booking.id === this.bookingId);
                if (booking) {
                    switch (this.direction) {
                        case 'from':
                            return booking.importDepartureAddress;
                        case 'to':
                            return booking.importDestinationAddress;
                        default:
                            return null;
                    }
                }
                return null;
            }),
        );
    }
}
