import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { FormValidators } from '@app/shared/forms';
import { GeoPositionModel } from '@app/store/planning/models';
import { OrderParcelFormCreateGroup, ParcelCreateFormGroup } from '../models/form.value';
import { PLANNING_TYPE } from '@app/store/planning/enums';
import { SERVICE_TYPES } from '@app/store/payment/enums';
import { CURRENCY_TYPES, ITEM_STATUS, OrderBookingParcelModel, OrderModel, PAYMENT_TYPES } from '@app/store/order';
import { CUSTOMER_TYPE } from '@app/store/order/enums/customer-type.enum';
import { ItemFormGroup, PaymentsCreateFormGroup } from '../../../shared/models/form.value';
import { ACCURACY_TYPES } from '@app/store/order/enums/accuracy.enum';
import { Subscription, tap } from 'rxjs';

@Injectable()
export class FormService implements OnDestroy {
    form: FormGroup<OrderParcelFormCreateGroup> = this.formBuilder.group<OrderParcelFormCreateGroup>({
        details: this.formBuilder.group({
            firstName: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required, FormValidators.Text] }),
            lastName: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required, FormValidators.Text] }),
            phone: this.formBuilder.control<string | null>('', { validators: [FormValidators.Required] }),
            email: this.formBuilder.control<string | null>('', { validators: [FormValidators.Email, FormValidators.Required] }),
            partner: this.formBuilder.control<string | null>(null, { validators: [FormValidators.Required] }),
            teamNumber: this.formBuilder.control<string | null>(null),
        }),
        invoiceDetails: this.formBuilder.group({
            nip: this.formBuilder.control<string | null>('', { validators: [FormValidators.ForeignNip, FormValidators.MaxLength(16)] }),
            isCompany: this.formBuilder.control<boolean | null>(null),
            invoice: this.formBuilder.control<boolean>(false),
            companyName: this.formBuilder.control<string | null>(''),
            address: this.formBuilder.group({
                city: this.formBuilder.control<null | string>(''),
                country: this.formBuilder.control<null | string>(''),
                houseNumber: this.formBuilder.control<null | string>(''),
                postalCode: this.formBuilder.control<null | string>(''),
                street: this.formBuilder.control<null | string>(''),
            }),
        }),
        parcels: this.formBuilder.array<FormGroup<ParcelCreateFormGroup>>([]),
        transactions: this.formBuilder.array<FormGroup<PaymentsCreateFormGroup>>([
            this.formBuilder.group({
                paymentMethod: this.formBuilder.control<PAYMENT_TYPES | null>(null, { validators: FormValidators.Required }),
                currency: this.formBuilder.control<CURRENCY_TYPES | null>(null, { validators: FormValidators.Required }),
            }),
        ]),
    });

    constructor(private formBuilder: NonNullableFormBuilder) {}
    private readonly subscriptions = new Subscription();

    setValue(order: OrderModel, bookingId: string) {
        this.form.patchValue(
            {
                details: {
                    firstName: order.customer.firstName,
                    lastName: order.customer.lastName,
                    phone: order.customer.phone,
                    email: order.customer.email,

                    partner: order.partner?.id || null,
                    teamNumber: order.teamNumber,
                },
                invoiceDetails: {
                    companyName: order.customer.companyName,
                    nip: order.customer.nip,
                    address: {
                        city: order.customer.address.city,
                        country: order.customer.address.country,
                        houseNumber: order.customer.address.houseNumber,
                        postalCode: order.customer.address.postalCode,
                        street: order.customer.address.street,
                    },
                    isCompany: order.customer.type === CUSTOMER_TYPE.COMPANY ? true : false,
                    invoice: order.invoice,
                },
            },
            { emitEvent: false },
        );

        const booking = order.bookings.find((booking) => booking.id === bookingId) as OrderBookingParcelModel | undefined; // #FIXME

        if (booking && this.form.controls.parcels.controls.length > 0) {
            this.form.controls.parcels.at(0).patchValue(
                {
                    id: null,
                    internalNotes: booking.internalNotes,
                    contentDescription: booking.parcel.contentDescription,
                    driverNotes: booking.driverNotes,
                    size: booking.parcel.size,
                    weight: booking.parcel.weight,
                    value: booking.parcel.value,
                    planningType: booking.planningType,
                    departureDate: booking.route.departureDate,
                    from: {
                        id: booking.route.from?.term || null,
                        term: booking.route.from?.term || '',
                        isPoint: booking.route.from?.isPoint || false,
                        country: booking.route.from?.country || null,
                        city: booking.route.from?.city || null,
                        street: booking.route.from?.street || null,
                        houseNumber: booking.route.from?.houseNumber || null,
                        postalCode: booking.route.from?.postalCode || null,
                        geoPosition: booking.route.from?.geoPosition || {
                            latitude: null,
                            longitude: null,
                        },
                    },
                    to: {
                        id: booking.route.to?.term || null,
                        term: booking.route.to?.term || '',
                        isPoint: booking.route.to?.isPoint || false,
                        country: booking.route.to?.country || null,
                        city: booking.route.to?.city || null,
                        street: booking.route.to?.street || null,
                        houseNumber: booking.route.to?.houseNumber || null,
                        postalCode: booking.route.to?.postalCode || null,
                        geoPosition: booking.route.to?.geoPosition || {
                            latitude: null,
                            longitude: null,
                        },
                    },
                    recipient: {
                        firstname: booking.parcel.recipient?.firstName || null,
                        surname: booking.parcel.recipient?.lastName || null,
                        email: booking.parcel.recipient?.email || null,
                        phone: booking.parcel.recipient?.phone || null,
                    },
                },
                { emitEvent: false },
            );
            const item = booking.items.find((item) => item.type === SERVICE_TYPES.RIDE);
            this.form.controls.transactions.at(0).patchValue({
                paymentMethod: order.payments.at(0)?.method,
                currency: order.transactions.at(0)?.currency,
            });
            if (item) {
                this.form.controls.parcels.at(0).controls.items.at(0).controls.price.patchValue(item.price, { emitEvent: false });
            }
        }

        // emit event at end
        this.form.patchValue(this.form.getRawValue());
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addBooking() {
        const itemsFormGroup = this.formBuilder.array<FormGroup<ItemFormGroup>>([]);
        itemsFormGroup.push(
            this.formBuilder.group({
                id: this.formBuilder.control<string | null>(null),
                type: this.formBuilder.control<SERVICE_TYPES | null>({ value: SERVICE_TYPES.RIDE, disabled: true }, { validators: [FormValidators.Required] }),
                price: this.formBuilder.control<number>(0, { validators: [FormValidators.Required] }),
                notes: this.formBuilder.control<string | null>(''),
                name: this.formBuilder.control<string | null>(null),
                bookingId: this.formBuilder.control<string | null>(''),
                status: this.formBuilder.control<ITEM_STATUS | null>(null),
            }),
        );

        const parcelFormGroup = this.formBuilder.group<ParcelCreateFormGroup>({
            id: this.formBuilder.control<string | null>(null),
            planningType: this.formBuilder.control<PLANNING_TYPE | null>(null, { validators: [FormValidators.Required] }),
            internalNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
            contentDescription: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
            driverNotes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
            notes: this.formBuilder.control<string | null>(null, { validators: FormValidators.MaxLength(1023) }),
            size: this.formBuilder.control<string>('', { validators: [FormValidators.Required] }),
            weight: this.formBuilder.control<number | null>(null, { validators: [FormValidators.Required, FormValidators.MinValue(0.1)] }),
            value: this.formBuilder.control<number>(250, { validators: [FormValidators.Required, FormValidators.MinValue(0.1)] }),
            departureDate: this.formBuilder.control<Date | null>(null, { validators: [Validators.required] }),
            didntGo: this.formBuilder.control(false),
            complaint: this.formBuilder.control(false),
            from: this.addBookingAddressForm(),
            to: this.addBookingAddressForm(),
            items: itemsFormGroup,
            recipient: this.addRecipientForm(),
        });

        this.subscriptions.add(
            this.form.controls.details.controls.partner.valueChanges
                .pipe(
                    tap((value) => {
                        this.form.controls.details.controls.partner.patchValue(value, { emitEvent: false });
                    }),
                )
                .subscribe(),
        );

        this.form.controls.parcels.push(parcelFormGroup);
    }

    removeBooking(index: number) {
        this.form.controls.parcels.removeAt(index);
    }

    private addRecipientForm() {
        return this.formBuilder.group({
            firstname: this.formBuilder.control<string | null>(null, {
                validators: [FormValidators.Required, FormValidators.Text],
            }),
            surname: this.formBuilder.control<string | null>(null, {
                validators: [FormValidators.Required, FormValidators.Text],
            }),
            email: this.formBuilder.control<string | null>(null, {
                validators: [FormValidators.Email, FormValidators.Required],
            }),
            phone: this.formBuilder.control<string | null>(null, {
                validators: [FormValidators.Required],
            }),
        });
    }
    private addBookingAddressForm() {
        return this.formBuilder.group({
            id: this.formBuilder.control<string | null>(null),
            term: this.formBuilder.control<string>(''),
            isPoint: this.formBuilder.control<boolean>({ value: false, disabled: true }),
            country: this.formBuilder.control<string | null>({ value: null, disabled: false }, { validators: [FormValidators.Required] }),
            countryRestricted: this.formBuilder.control<boolean>({ value: true || null, disabled: true }),
            city: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            street: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            houseNumber: this.formBuilder.control<string | null>({ value: null, disabled: true }, { validators: [] }),
            accuracy: this.formBuilder.control<ACCURACY_TYPES>({ value: ACCURACY_TYPES.HOUSE_NUMBER, disabled: true }, { validators: [] }),
            postalCode: this.formBuilder.control<string | null>(
                { value: null, disabled: true },
                {
                    validators: [FormValidators.MaxLength(10)],
                },
            ),
            geoPosition: this.formBuilder.control<GeoPositionModel>({
                latitude: null,
                longitude: null,
            }),
        });
    }
}
